import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Input } from "@nextui-org/react";
import React , { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CanvasContext } from "./contexts/canvas-context";
import { setPageRealDistanceCalibration, setAllPagesScale} from "../../store/components/PolygonEditor/ImageSlice";
import { useDrawShape} from"./contexts/draw-context";
import { setOpacity, setStrokeColor } from "../../store/components/PolygonEditor/CanvasSlice";
import { ShapeType } from "./hooks/draw";
import YesNoAlert from '../modals/AlertYesNo';
 

const Calibration = ({
    activeFunction, // "line_calibration", "calibration"
    setActiveFunction,
    page,
    nbPages
}) => {
    const calibrationPoints = useSelector((state) => state.image.calibration?.find(c=>c.page === page)?.points ?? []);
    const calibrationDistance = useSelector((state) => state.image.calibration?.find(c=>c.page === page)?.real_distance ?? 0);
    const allPagesHaveSameScale = useSelector((state) => state.image.calibration?.every((p, _, arr) => p.scale === arr[0].scale));
    const { canvasRef, setIsDeleting , setShapeType , getAllObjects , removeObjects , getObjectById , setMaxPoints } = useContext(CanvasContext) ;
    const dispatch = useDispatch();
    const { startDrawing,insertPolygon,zoomObjects } = useDrawShape();
    const calibrationData = useSelector((state) => state.image.calibration?.find(c=>c.page === page) ?? undefined );


    const scaleLayerId = useSelector((state) => {
      let scale = state.image.layers?.find(x=>x.name === "scale_foreground_vpf");
      if (scale) {
        return scale.layerId;
      }
      else {
        return -1;
      }
    });

    const changeCalibrationValue = (value) => {
        dispatch(setPageRealDistanceCalibration({
          page: page,
          realDistance: parseFloat(value)
        }));
      };

    const drawCalibration = () => {
      setIsDeleting(false);
      if (calibrationData !== undefined) {
        removeObjects(getAllObjects().filter(x=>x.layer === scaleLayerId && x.calibration_line === true).map(x=>x.id));
      }
      setActiveFunction("line_calibration");
      dispatch(setStrokeColor("#000000"));
      dispatch(setOpacity(1));
      setMaxPoints(2);
      setShapeType(ShapeType.POLYLINE);
      startDrawing();
    };

    const setAllPages = () => {
      const isSettingScale = YesNoAlert(
        "Echelle commune",
        `L'échelle de la page ${page} va être appliquée à toutes les pages. Continuer?`,
        {
          yesText: "OK",
          noText: "Annuler",
          onYes: () => {
            dispatch(setAllPagesScale({page, nbPages}))
          },
          yesButtonProps: {
            color: "danger",
        },
        }
      );
    };

    return <div className='flex flex-col gap-y-2'>
              <div>Calibration</div>
              <div className='flex flex-row justify-around items-center align-middle gap-x-4'>
                  <Button onClick={drawCalibration} color={activeFunction === "line_calibration" ? "danger" : "default"} className="shadow-lg"><Icon icon="lets-icons:line-out" className='scale-150' />Sélectionner une longueur</Button>
                  {(calibrationPoints && calibrationPoints.length === 2) && <Icon icon="entypo:check" className='scale-[2]' />}
              </div>
              <Input label="Longueur associée" defaultValue={calibrationDistance} type='number' min={.01} onValueChange={changeCalibrationValue} endContent={
                  <div className="pointer-events-none flex items-center">
                      <span className="text-default-400 text-small">m</span>
                  </div>
              } />
              <Button isDisabled={allPagesHaveSameScale} onClick={setAllPages} className="shadow-lg">
                {allPagesHaveSameScale ? 
                    <div className="inline-flex items-center">
                      <Icon icon="entypo:check" className="mr-1"/> Toutes les pages ont la même échelle` 
                    </div>
                    : <div>Appliquer l'échelle à toutes les pages</div>
                }
              </Button>
              {/* <Button isDisabled={!calibrationReady} onClick={drawCalibration} color={activeFunction === "calibration" ? "danger" : "default"}><Icon icon="ph:ruler" className='scale-150' />Calibrer</Button> */}
          </div>
};

export default Calibration;
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef,useState } from "react";
import { GeoJSON, MapContainer, TileLayer, useMap,Polyline,Popup } from "react-leaflet";
import "react-leaflet-fullscreen/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { updateSelectedRoads } from "../../../../store/features/PYM/GeoSlice";
import Geoman from "./geoman";
import GeotiffLayer from "./geotif";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import Selection from "./selection";
import { useDarkMode } from '@rbnd/react-dark-mode';

const MapInit = ({
  dataPoints,
  zoomPoint,
  show_roads,
  show_tools,
  show_lights =true,
  onSetRectangleCoords = () => {},
  onSetZoneCalcul = () => {},
  onSetRoads = () => {},
  onSelection = () => {}, // Fonction pour effacer les éléments
}) => {
  const map = useMap();
  const dispatch = useDispatch();
  const dataRoads = useSelector((state) => state.PYM.GeoData.selected_roads);
  const dataAllRoads = useSelector((state) => state.PYM.GeoData.roads_data);
  const colorsRoads = useSelector((state) => state.PYM.ProjectInfos.axes_colors);
  
  const { mode, setMode } = useDarkMode()
  
  const [roadTypes, setRoadTypes] = useState(new Set());

  const rectangleRef = useRef(null);
  const roadsRef = useRef([]);

  var geojsonMarkerOptions = {
    radius: 2,
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
  };

  const getColor = (roadType) => {
    const item = colorsRoads.find(element => element.category_name === roadType);
    return item ? item.color : '#2a9d8f';
  }

  useEffect(() => {
    if (dataRoads && dataRoads.length > 0) {
      setRoadTypes(new Set(dataRoads.map((y) => y.tags.highway).flat()));
    } else if(dataAllRoads && dataAllRoads.features.length > 0) {
      const tmp = dataAllRoads.features.map(element => element.properties);
      setRoadTypes(new Set(tmp.map((y) => y.category).flat()));
    }
  }, [dataRoads, dataAllRoads]);

  const setPoint = ({ properties }, latlng) => {
    return L.circleMarker(latlng, geojsonMarkerOptions);
  };
  function getCoordinate(layer) {

    var feature = layer.toGeoJSON();

    var zone_calculs = feature.geometry.coordinates;
    onSetZoneCalcul(zone_calculs);
    // layer.bindPopup(zone_calculs.toString());
    return zone_calculs;
  }
  function getCoordinates(layer) {
    const bounds = layer.getBounds();
    const coords = [
      [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
      [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
    ];
    onSetRectangleCoords(coords);
    return coords;
  }

  useEffect(() => {
    map.on("pm:create", function (e) {
      var layer = e.layer;
      rectangleRef.current = layer;
      const coords = getCoordinates(layer);
      onSetRectangleCoords(coords);
      layer.on("pm:update", function (e) {
        const updatedCoords = getCoordinates(e.layer);
        onSetRectangleCoords(updatedCoords);
      });
    });
  }, [map]);

  return (
    <>
      {mode==='' ? <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />:<TileLayer url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png" />}
      {show_lights && <GeoJSON data={dataPoints} pointToLayer={setPoint}/>}
      {show_roads && Array.from(roadTypes).map((roadType, index) => {
        const color = getColor(roadType);
        const filteredRoads = dataAllRoads.features.filter((element) => element.properties.category === roadType);
        return filteredRoads.map((element) => (
          <Polyline
            key={`${element.id}}`}
            id={`${element.properties.id}`}
            positions={element.geometry.coordinates.map((p) => [p[1], p[0]])}
            color={color}
          >
            <Popup>
              <div>
                <h3>{roadType}</h3>
              </div>
            </Popup>
          </Polyline>
        ));
      })}
      {show_roads &&
      <Selection onSelection={onSelection}/>}
      {show_tools && <Geoman />}
      <FullscreenControl />
    </>
  );
};

const LeafletMap = ({ onRoadsChange, onRectangleCoordsChange,show_roads=false,show_tools=false,needPlot=false,plotPath="",show_lights=true,onSelection=()=>{}}) => {
  const dataPoints = useSelector((state) => state.PYM.GeoData.city_lights_positions);
  const zoom_point = useSelector((state) => state.PYM.GeoData.zoom_point);
  
  const location = useLocation();

  needPlot = location.state?.needPlot ?? needPlot;
  plotPath= location.state?.plotPath ?? plotPath;

    const handleMapCreated = (map) => {
      if (map) {
        map.on('load', function() {
          map.invalidateSize();
      });      }
    };

  if (
    dataPoints === undefined ||
    dataPoints.length === 0) {
    return <Navigate to="/pym" />;
  }
  
  // Fonction pour envoyer le payload lors du clic sur le bouton continuer
  return (
    <>        
      <div className="relative h-full w-full">
        <MapContainer
          ref={handleMapCreated}
          className="rounded-lg z-0"
          center={zoom_point}
          zoom={15}
          maxZoom={18}
          // minZoom={10}
          attributionControl={false}
          scrollWheelZoom={true}
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            zIndex: "0  !important",
          }}
        >
          <MapInit
            dataPoints={dataPoints}
            zoomPoint={zoom_point}
            onSetRectangleCoords={onRectangleCoordsChange}
            onSetRoads={onRoadsChange}
            show_roads={show_roads}
            show_tools ={show_tools}
            show_lights ={show_lights}
            onSelection ={onSelection}
          />
          {needPlot && <GeotiffLayer url ={plotPath}/>}
        </MapContainer>
      </div>
    </>
  );
};
export default LeafletMap;

import React, { useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Button, Select, SelectItem } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import EditableTable from "../../../../components/table/editable-table";
import ResultDisplay from "./result-display";

export default function LightsSelectionCard({ lightsFiles, setSelectedIES, props }) {
  const [filters, setFilters] = React.useState([]);

  var initial_visible_columns = useMemo(() => {
    return [
      {
        name: "Designation",
        uid: "designation",
        isEditable: true,
        type: 'text',
      }
    ];
  }, [lightsFiles]);
  const handleOnRowSelected = (key) => {
    setSelectedIES((ies) => {
      let newTabs = []
      if (key === 'all') {
        newTabs = lightsFiles
        return newTabs
      }
      else {
        newTabs = Array.from(key).map((k) => (lightsFiles[parseInt(k)-1]))
        return newTabs

      }
    }

    );
  }

  function formatLightsFile(lightsFiles) {
    return lightsFiles.map((file, index) => ({
      id: index + 1,
      designation: file
    }));
  }
  return (
    <Card className="bg-content2" shadow="none" {...props}>
      <CardHeader className="flex flex-col gap-2 px-4 pb-4 pt-6">
        {/* <Icon icon="solar:lightbulb-line-duotone" width={40} /> */}
        <p className="text-medium text-content2-foreground">Choix luminaire(s)</p>
      </CardHeader>
      <CardBody className="flex flex-col gap-2">
        <div className="bg-white rounded-large shadow-small px-2 py-2 flex flex-row flex-nowrap justify-around items-center">
          <EditableTable
            selectionMode="multiple"
            defaultSelectedKeys={["1"]}
            columns={initial_visible_columns}
            data={formatLightsFile(lightsFiles)}
            // setData={setRoadProfileData}
            pagination={false}
            showTopRibbon={false}
            filters={filters}
            rowsPerPage={5}
            onSelectionChange={(key) => {
              handleOnRowSelected(key);
            }
            }
          />
        </div>
      </CardBody>
    </Card>
  );

}

import { createContext } from "react";

const CanvasEventContext = createContext({
    onShapeAdded: (shape) => {},
    onShapeRemoved: (shape) => {},
    onShapeSelected: (shape) => {},
    onShapeDeselected: (shape) => {},
    onShapeClicked: (shape) => {},
    onEndPrompt: (pointer) => {},
  });

export { CanvasEventContext };
import React, { useCallback, useContext, useEffect, useState } from "react";
import PDFEditor from "../../../../components/polygon-editor/pdf-editor";
import SymbolSelector from "./symbolSelector";
import { useDispatch, useSelector } from "react-redux";
import SideMenu, { Functions } from "./sideMenu";
import { CanvasContext, CanvasProvider } from "../../../../components/polygon-editor/contexts/canvas-context";
import { SymbolsProvider } from "./provider";
import { DrawShapeProvider, useDrawShape } from "../../../../components/polygon-editor/contexts/draw-context";
import { ShapeType } from "../../../../components/polygon-editor/hooks/draw";
import { addLegendStore, removeLegendStore, addLinearStore, addSymbolStore, changeLinearColorStore, changeSymbolColorStore, removeLinearStore, removeSymbolStore } from "../../../../store/features/Comptage/configuration";
import { setFillColor, setOpacity } from "../../../../store/components/PolygonEditor/CanvasSlice";
import { fabric } from 'fabric';
import Colors from "../color";
import { v4 as uuidv4 } from 'uuid';
import { addObjectInLayer } from "../../../../store/components/PolygonEditor/ImageSlice";
import YesNoAlert, { DianeYesNoModal } from "../../../../components/modals/AlertYesNo";
import Alert from "../../../../components/modals/Alert";
import { defaultConfidenceRate, defaultOpacity, defaultPointSize } from "../count";
import useExtract from "../../../../components/polygon-editor/hooks/extract";
import { Image, useDisclosure } from '@nextui-org/react';


import { useOutletContext } from "react-router-dom";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import config from "../../../../config";

const containerName = "comptagev2";

export default function Symbols () {
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();

  fabric.Group.prototype.hasControls = false;
  fabric.Group.prototype.lockMovementX = false;
  fabric.Group.prototype.lockMovementY = false;
  fabric.Group.prototype.lockRotation = false;

  return (
    <CanvasProvider className="overflow-hidden h-[99vh]">
      <SymbolsComponent
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        loadingMessage={loadingMessage}
        setLoadingMessage={setLoadingMessage}
      />
    </CanvasProvider>
  );
};

export function SymbolsComponent ({
    isLoading,
    setIsLoading,
    loadingMessage,
    setLoadingMessage
}) {
  const dispatch = useDispatch();
  const [activeFunction, setActiveFunction] = useState(Functions.NONE);
  const { angle } = useContext(CanvasContext);
  const { extractContentWithinShape } = useExtract();
  const [page, setPage] = useState(0);
  const backgroundLayerId = useSelector((state) => {
    let background = state.image.layers.find(x=>x.name === "background");
    if (background) {
      return background.layerId;
    }
    else {
      return -1;
    }
  });
  const symbolLayerId = useSelector((state) => {
    let symbol = state.image.layers.find(x=>x.name === "symbol");
    if (symbol) {
      return symbol.layerId;
    }
    else {
      return -1;
    }
  });

  const addSymbol = (shape) => {
    let isFullLegend = (activeFunction === Functions.LEGEND);
    setActiveFunction(Functions.NONE);
    shape.selectable = true;
    shape.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      bl: false,
      br: false,
      tl: false,
      tr: false,
      mtr: false
    });        
    shape.lockRotation = true;
    shape.lockMovementX = true;
    shape.lockMovementY = true;
    shape.lockScalingX = true;
    shape.lockScalingY = true;

    let pts = (shape.isType("rect") ? [{
          x: shape.left,
          y: shape.top
      }, {
          x: shape.left + shape.width,
          y: shape.top
      }, {
          x: shape.left + shape.width,
          y: shape.top + shape.height
      }, {
          x: shape.left,
          y: shape.top + shape.height
      }] : (shape.isType("polygon") ? shape.points : []));

    
    if (angle !== 0) {
      var [originX, originY] = [0, 0];

      pts = Array.from(pts).map(x=>{return {
          x: (x.x - originX) * Math.cos(-angle * Math.PI / 180) - (x.y - originY) * Math.sin(-angle * Math.PI / 180) + originX,
          y: (x.x - originX) * Math.sin(-angle * Math.PI / 180) + (x.y - originY) * Math.cos(-angle * Math.PI / 180) + originY
      }});
    }
    let symbol = {
      "id": shape.id,
      "crop_id": shape.id,
      "page": page,
      "points": pts,
      "color": shape.fill,
      "confidence_rate": defaultConfidenceRate,
      "isVisible": true,
      "point_size": defaultPointSize,
      "opacity": defaultOpacity,
      "name": `Symbol`
    };

    dispatch(addObjectInLayer({layerId: symbolLayerId, object: shape.id})); // Add the object's ID to Redux store
    
    if (isFullLegend) {
      dispatch(addLegendStore(symbol));
    } else {
      dispatch(addSymbolStore(symbol));
    };
  };

  const removeSymbol = (shape) => {
    dispatch(removeSymbolStore(shape.id));
    dispatch(removeLegendStore(shape.id));
  };


  return (
    <SymbolsProvider
      addSymbol={addSymbol}
      removeSymbol={removeSymbol}
    >
      <DrawShapeProvider>
        <SymbolEditor
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          loadingMessage={loadingMessage}
          setLoadingMessage={setLoadingMessage}
          page={page}
          setPage={setPage}
          activeFunction={activeFunction}
          setActiveFunction={setActiveFunction}
        />
      </DrawShapeProvider>
    </SymbolsProvider>
  );
};

const SymbolEditor = ({ isLoading, setIsLoading, loadingMessage, setLoadingMessage, page, setPage, activeFunction, setActiveFunction }) => {
  const { launch_request } = useAzureFuncApp();

  const {isOpen, onOpen, onOpenChange, onClose} = useDisclosure();

  const projectFiles = useSelector((state) => state.Comptage.ProjectInfos.project_files);
  const projectName = useSelector((state) => state.Comptage.ProjectInfos.project_name);
  const user = useSelector((state) => state.Comptage.ProjectInfos.creator_name);
  const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
  const currentFilePages = useSelector((state) => state.Comptage.Configuration.pages);
  const currentFileData = projectFiles.find(x =>x.name === currentFile);
  const selectedPages = useSelector((state) => state.Comptage.Configuration.pages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]));
  const { canvasRef, setIsDeleting, setShapeType, getAllObjects, removeObjects, getObjectById } = useContext(CanvasContext);
  const { startDrawing, insertPolygon, zoomObjects } = useDrawShape();
  const { extractContentWithinShape } = useExtract();
  const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
  const legend = useSelector((state) => state.Comptage.Configuration.legend);
  const linears = useSelector((state) => state.Comptage.Configuration.linears);
  const [candidateSymbols, setCandidateSymbols] = useState([]);
  const [selectedSymbols, setSelectedSymbols] = useState([]);
  const dispatch = useDispatch();
  const symbolLayerId = useSelector((state) => {
    let symbol = state.image.layers.find(x=>x.name === "symbol");
    if (symbol) {
      return symbol.layerId;
    }
    else {
      return -1;
    }
  });
  const backgroundLayerId = useSelector((state) => {
    let background = state.image.layers.find(x=>x.name === "background");
    if (background) {
      return background.layerId;
    }
    else {
      return -1;
    }
  });

  const restoreSymbols = (page) => {
    for(let symbol of [...symbols.filter(x=>x.page === page), ...((legend?.page === page) ? [legend] : [])]) {
      let shape = insertPolygon(symbol["points"], {
        id: symbol["id"],
        crop_id: symbol["crop_id"],
        stroke: symbol["color"],
        fill: symbol["color"],
        opacity: .4,
        selectable: true,
        strokeWidth: 2,
        objectCaching: false,
        visible: true,
        layer: symbolLayerId,
        lockRotation: true,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true
      });

      
      shape.selectable = true;
      shape.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
          mtr: false
        });        
      shape.lockRotation = true;
      shape.lockMovementX = true;
      shape.lockMovementY = true;
      shape.lockScalingX = true;
      shape.lockScalingY = true;
    }
  };

  const addLegend = () => {
    if (legend.id) {
      let obj = getObjectById(legend.id);
      if (obj) {
        obj.definitiveDelete = true;
        removeObjects([obj.id])
      }
    };
    setActiveFunction(Functions.LEGEND);
    setIsDeleting(false);
    dispatch(setFillColor("#dfdfdf"));
    dispatch(setOpacity(.4));
    setShapeType(ShapeType.RECT);
    startDrawing();
  };

  const extractLegend = async () => {
    if (legend.points) {
      setLoadingMessage("Extraction de la légende en cours...");
      setIsLoading(true);

      if (legend.id) {
        let obj = getObjectById(legend.id);
        if (obj) {obj.visible = false};
      };

      // Only get the candidate symbols the first time we click on "Extraire légende"
      if (candidateSymbols.length === 0) {

        let payload = {
          "function": "extract_legend",
          "username": user,
          "project_name": projectName,
          "container_name": containerName,
          "pdf_name": currentFile,
          "page_number": page,
          "legend_position": legend.points,
          "legend_id": legend.id
        };

        console.log(user, projectName, containerName, currentFile, page, legend.points, legend.id);

        let [status_code, result] = await launch_request(config.apiFastsamCountUri, payload);

        if (status_code === 200) {
          let legendItems = result.legend_items;
          const currentZoom = canvasRef.current.getZoom();

          legendItems.forEach((item, index) => {

            //Check if this item is already in symbols
            let symbol = symbols.find(s => ((JSON.stringify(s.points) === JSON.stringify(item.points)) && (page === s.page)));
            let shape;
            if (symbol) {
              item.id = symbol.id;
              item.name = symbol.name;
              shape = getObjectById(symbol.id);
              shape.visible = true;
              setSelectedSymbols(s => {
                let newS = [...s, symbol.id];
                return newS;
              });
            } else {
              shape = insertPolygon(item["points"], {
                stroke: Colors[index % Colors.length].value,
                fill: Colors[index % Colors.length].value,
                opacity: .4,
                selectable: false,
                strokeWidth: 2,
                objectCaching: false,
                visible: false,
                layer: symbolLayerId,
                lockRotation: true,
                lockMovementX: true,
                lockMovementY: true,
                lockScalingX: true,
                lockScalingY: true
              });
          
              shape.setControlsVisibility({
                  mt: false,
                  mb: false,
                  ml: false,
                  mr: false,
                  bl: false,
                  br: false,
                  tl: false,
                  tr: false,
                  mtr: false
                });
  
              item.id = shape.id;
              item.name = item.labels[0].value;
            };
            
            if (index === 0) {
              canvasRef?.current.setZoom(1);
              const symbolZoom = Math.min(canvasRef?.current.width / (shape.width * 1.05), canvasRef?.current.height / (shape.height * 1.05)) / 10;
              canvasRef?.current.setZoom(symbolZoom);
            };
        
            item.imageUrl = extractContentWithinShape(shape, [backgroundLayerId]);
          });
          canvasRef?.current.setZoom(currentZoom);
          
          setCandidateSymbols(legendItems);
          console.log("Legend items : ", legendItems);
          setIsLoading(false);
          onOpen();
        };
      } else {
        setIsLoading(false);
        onOpen();
      };

      setIsLoading(false);
    } else {
      Alert("Veuillez indiquer l'emplacement de la légende (détourer légende)");
    };
  };

  const makeLegendVisible = () => {
    if (legend.id) {
      let obj = getObjectById(legend.id);
      if (obj) {obj.visible = true};
    }
    onClose();
  };

  const saveSelectedSymbols = () => {
    candidateSymbols.forEach(s => {
      let shape = getObjectById(s.id);
      let symbol = {...s, 
        "crop_id": s.id,
        "page": page,
        "color": shape.fill,
        "confidence_rate": defaultConfidenceRate,
        "isVisible": true,
        "point_size": defaultPointSize,
        "opacity": defaultOpacity};
      if (selectedSymbols.includes(s.id)) {
        if (!symbols.map(sym => sym.id).includes(s.id)) { // Add symbol if it is not already in symbols.
          dispatch(addObjectInLayer({layerId: symbolLayerId, object: shape.id}));
          dispatch(addSymbolStore(symbol));
        };
      } else {
        dispatch(removeSymbolStore(s.id)); // If the symbol was previously in the store but is now unselected, remove it.
      }
    });
    onClose();
  };

  // Makes symbols that are selected (with extractLegend) visible
  useEffect(() => {
    candidateSymbols.forEach(s => {
      let shape = getObjectById(s.id);
      if (shape) {
        if (selectedSymbols.includes(s.id)) {
          shape.visible = true;
          shape.selectable = true;
        } else {
          shape.visible = false;
          shape.selectable = false;
        };
        canvasRef.current.renderAll();
      };
    });
    console.log("selected : ", selectedSymbols);
    console.log("candidate : ", candidateSymbols);

    if (legend.id) {
      const currentZoom = canvasRef.current.getZoom();
      canvasRef?.current.setZoom(1);

      let shape = getObjectById(legend.id);
      if (shape) {
        const legendZoom = Math.min(canvasRef?.current.width / (shape.width * 1.05), canvasRef?.current.height / (shape.height * 1.05)) * 2;
        canvasRef?.current.setZoom(legendZoom);
        let imageUrl = extractContentWithinShape(shape, [backgroundLayerId, symbolLayerId]);
        dispatch(addLegendStore({...legend, "imageUrl": imageUrl}));
        canvasRef?.current.setZoom(currentZoom);
      }};
  }, [selectedSymbols, candidateSymbols]);

  const addPolygonalSymbol = () => {
    setActiveFunction(Functions.POLY);
    setIsDeleting(false);
    dispatch(setFillColor(Colors[symbols.length % Colors.length].value));
    dispatch(setOpacity(.4));
    setShapeType(ShapeType.POLYGON);
    startDrawing();
  };
  const addRectangularSymbol = () => {
    setActiveFunction(Functions.RECT);
    setIsDeleting(false);
    dispatch(setFillColor(Colors[symbols.length % Colors.length].value));
    dispatch(setOpacity(.4));
    setShapeType(ShapeType.RECT);
    startDrawing();
  };
  const deleteSymbol = () => {
    setActiveFunction(Functions.DELETE);
    setIsDeleting(true);
  };
  const deleteAllSymbols = () => {
    setActiveFunction(Functions.DELETE_ALL);
    YesNoAlert({
      title: "Supprimer",
      body: "Voulez-vous vraiment supprimer tous les symboles ?",
      onYes: () => {
        setIsDeleting(false);
        let objs = getAllObjects().filter(x=>!x.isBackground);
        objs.forEach(sh => {
          sh.definitiveDelete = true;
        });
        removeObjects(objs.map(x => x.id));
        setActiveFunction(Functions.NONE);
      },
      onNo: () => {
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      },
      onClose: () => {
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      }
    });
  };

  const zoomItem = useCallback((id) => {
    zoomObjects([id], 1.3);
  }, [zoomObjects]);

  const deleteItem = useCallback((id) => {
    let objs = getAllObjects().filter(x=>x.id === id);
    objs.forEach(sh => {
      sh.definitiveDelete = true;
    });
    removeObjects(objs.map(x => x.id));
  }, [getAllObjects, removeObjects]);

  const changeSymbolColor = useCallback((id, newColor) => {
    let obj = getObjectById(id);
    if (obj) {
      obj.stroke = newColor;
      obj.fill = newColor;
    }
    canvasRef.current.renderAll();

    dispatch(changeSymbolColorStore({id, newColor}));
  }, [canvasRef, dispatch, getObjectById]);

  const addLinear = () => {
    let id = uuidv4();
    dispatch(addLinearStore({
      id,
      linear_id: id,
      name: `Linéaire`,
      color: Colors[linears.length % Colors.length].value,
      isVisible: true
    }));
  };

  const removeLinear = (id) => {
    dispatch(removeLinearStore(id));
  };

  const changeLinearColor = (id, newColor) => {
    dispatch(changeLinearColorStore({id, newColor}));
  };

  return (
          <>
          <PDFEditor
            initialLayers={["0", "background", "symbol"]}
            page={page}
            setPage={setPage}
            showScale={false}
            toolsPanel={
              <div className="h-full top-0 absolute overflow-hidden right-0 z-50">
                <SideMenu
                  activeFunction={activeFunction}
                  addLegend={addLegend}
                  extractLegend={extractLegend}
                  addPolygonalSymbol={addPolygonalSymbol}
                  addRectangularSymbol={addRectangularSymbol}
                  deleteSymbol={deleteSymbol}
                  deleteAllSymbols={deleteAllSymbols}
                  zoomItem={zoomItem}
                  deleteItem={deleteItem}
                  changeSymbolColor={changeSymbolColor}
                  page={page}
                  addLinear={addLinear}
                  removeLinear={removeLinear}
                  changeLinearColor={changeLinearColor}
                />
              </div>}
            visiblePages={selectedPages}
            pageCount={currentFileData === undefined ? 1 : currentFileData.pages.length}
            getImages={(p) => {
              return currentFilePages[p]["patches"];
          } }
            restoreShapes={restoreSymbols}
          />
          <DianeYesNoModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={<div>Symboles détectés</div>}
            body={ <SymbolSelector
                      symbols={candidateSymbols}
                      setSymbols={setCandidateSymbols}
                      selectedSymbols={selectedSymbols}
                      setSelectedSymbols={setSelectedSymbols}
                      legendImage={legend.imageUrl}
                    />
                   }
            onClose={makeLegendVisible}
            onYes={saveSelectedSymbols}
            onNo={onClose}
            yesText={"Valider"}
            noText={"Annuler"}         
            scrollBehavior="inside"
            classNames={{
              base: "min-w-[80vw] min-h-[80vh]"
            }}
          />
          </>);
}


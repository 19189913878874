import { Canvas, useThree } from '@react-three/fiber';
// import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

import React, { useRef, useState, useEffect } from 'react'
import { Icon } from "@iconify/react";
import { Input, Button,Select,SelectItem } from "@nextui-org/react";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import config from "../../../../config";
import { useDispatch, useSelector } from "react-redux";

import RoadTypeCard from "./road-type-card";
import { RoadTypeEnum } from "./roads-types"
import ConfirmationSavedModal from './confirmation-saved-modal';
import { useOutletContext } from 'react-router-dom';
import WarningModal from '../components/warning-modal';

const lightCat= ["C0", "C1", "C2", "C3", "C4", "C5", "M1", "M2", "M3", "M4", "M5", "M6"];
const roadCat= ["Axe principal", "Axe secondaire", "Axe tertiaire", "Axe piéton"];

export default function Definition() {
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  const { launch_request } = useAzureFuncApp();
  const [showModal,setShowModal] = useState(false);
  const [showModalWarning,setShowModalWarning] = useState(false);

  const [roadProfileData, setRoadProfileData] = React.useState(
    [
      {
        id: 1, type: RoadTypeEnum.Chaussee, designation: 'Chaussée 1', width: 7, nb_way: 2, height: 0, lighting_class: 'C0',light_height:8, category:'Axe principal',rectangle: {
          "width": 15,
          "height": 3.5,
          "position": [
            0,
            2.25,
            0
          ],
          "color": "#5c523e"
        }
      },
      {
        id: 2, type: RoadTypeEnum.Trottoir, designation: 'Trottoir 1', width: 2, nb_way: 0, height: 0.10, lighting_class: 'C0',light_height:8, category:'Axe principal',rectangle: {
          "width": 15,
          "height": 1,
          "position": [
            0,
            0,
            0
          ],
          "color": "#a49e87"
        }
      },
    ]);
  const project_infos = useSelector((state) => state.PYM.ProjectInfos);

  const [selectedRoadType, setSelectedRoadType] = React.useState(roadProfileData[0]);
  const [selectedroadCat, setSelectedroadCat] = React.useState("Axe principal");
  const [roadProfileName, setRoadProfileName] = React.useState('Voirie type 1');
  const [lightHeight, setLightHeight] = React.useState(8);
  const [lightingClass, setLlightingClass] = React.useState("C0");
  const [messagesWarnings,setMessagesWarnings] = React.useState("");
  const validateNumberAndDot = (input) => {
    return new String(input).match(/^(?!\.$)[0-9.]+$/)
  };

  function FitView() {
    const { camera, scene } = useThree();

    useEffect(() => {
      const box = new THREE.Box3().setFromObject(scene);
      const size = box.getSize(new THREE.Vector3()).length();
      const center = box.getCenter(new THREE.Vector3());

      camera.position.set(center.x, center.y, size * 1.5); // Position au-dessus de la scène
      camera.zoom = 50; // Zoom initial
      camera.updateProjectionMatrix();

      camera.lookAt(center.x, center.y, 0); // Regarder vers le bas
    }, [scene, camera]);
    return null;
  }
  const Rectangle = ({ width, height, color, position }) => {
    return (<>
      <mesh position={position}>
        <planeGeometry args={[width, height]} />
        <meshStandardMaterial color={color} />
      </mesh>
    </>
    );
  };
  function readyToGoNext() {
    let messages = [];
    let isValid = true;
    if (!roadProfileName || roadProfileName.trim() === "") {
        messages.push("- Merci de renseigner un nom pour le tronçon type.");
        isValid = false;
    }
    if (!selectedroadCat || selectedroadCat.trim() === "") {
        messages.push("- Merci de renseigner une catégorie pour la voirie.");
        isValid = false;
    }
    if (!lightingClass || lightingClass.trim() === "") {
        messages.push("- Merci de renseigner une classe d'éclairage.");
        isValid = false;
    }
    if (!lightHeight || lightHeight.trim() === "") {
        messages.push("- Merci de renseigner une hauteur de luminaire.");
        isValid = false;
    }
    if (!isValid) {
       setMessagesWarnings(messages.join("\n\n"));
       setShowModalWarning(true);
    }

    return isValid;
  }
  const handleSaveRoadType = async (item) => {
    if(readyToGoNext()){
      let payload = {
        'functionName': 'saveRoadType',
        'pathFiles': project_infos.project_path,
        'name': roadProfileName,
        'category':selectedroadCat,
        'lightsHeight':lightHeight,
        'lightingClass':lightingClass,
        'data': roadProfileData
      }
      setLoadingMessage("Sauvegarde des tronçons types...");
      setIsLoading(true);
      let [status_code, result] = await launch_request(
        config.pymParseFile,
        payload
      );
      if (status_code === 200) {
        setShowModal(true);
      }
      setIsLoading(false);
    }
    else{

    }
  }
  const handleRowSelected = (item) => {
    setSelectedRoadType(item);
  }
    const handleChange = (value,key) =>{
      setRoadProfileData((items) => {
        let prevItems = [...items];
        prevItems = prevItems.map(dictionnaire => ({
          ...dictionnaire,
          [key]: value
      }));
      return prevItems
    });
     
  }

  const isInvalidH = React.useMemo(() => {
    if (lightHeight === "") return false;

    return validateNumberAndDot(lightHeight) && lightHeight > 0 ? false : true;
  }, [lightHeight]);
  
  return (
    <div className='w-full flex flex-col mt-2 ml-2 mr-2 mb-2'>
      <div className='flex flex-row gap-2 items-center my-4'>
        <Input
          label="Nom de la catégorie de voirie"
          value={roadProfileName}
          onValueChange={(value) => {
            setRoadProfileName(value)
          }
          }
        />
        <Select
          label="Catégorie"
          className="max-w-xs my-4"
          defaultSelectedKeys={["Axe principal"]}
          onSelectionChange={(event)=>{
            setSelectedroadCat(event.currentKey)
            handleChange(event.currentKey,'category')
          }}
        >
        {roadCat.map((cat) => (
          <SelectItem  key={cat}>
            {cat}
          </SelectItem>
        ))}
      </Select>

      <Select
          label="Classe d'éclairage"
          className="max-w-xs my-4"
          defaultSelectedKeys={["C0"]}
          onSelectionChange={(event)=>{
            setLlightingClass(event.currentKey)
            handleChange(event.currentKey,'lighting_class')}}
          >
        {lightCat.map((cat) => (
          <SelectItem  key={cat}>
            {cat}
          </SelectItem>
        ))}
      </Select>
      <Input
          label="Hauteur des mats"
          value={lightHeight}
          isInvalid={isInvalidH}
          color={isInvalidH && "danger" }
          onValueChange={(value)=>{
              setLightHeight(value);
              handleChange(value,'light_height');
        }}
        />
        <Button isIconOnly onClick={() => { handleSaveRoadType() }} className='h-[63%] bg-[#80ed99] ' ><Icon icon="fluent:save-24-regular" width={55} height={55} style={{ 'color': 'white' }} /></Button>
      </div>
      <div className='flex flex-row align-items h-full overflow-y-hidden'>
      <RoadTypeCard
        selectedRoadType={selectedRoadType}
        roadProfileData={roadProfileData}
        setRoadProfileData={setRoadProfileData}
        onRowSelected={handleRowSelected}
      />
      <div className='w-[50%]'>
        <Canvas orthographic camera={{ position: [0, 0, 100], zoom: 50 }}>
          {roadProfileData.map((road, index) => (
            <Rectangle
              key={index}
              width={road.rectangle.width}
              height={road.rectangle.height}
              color={road.rectangle.color}
              position={road.rectangle.position}
            />
          ))}
          <ambientLight intensity={0.1} />
          <directionalLight position={[0, 0, 5]} />
          <FitView />
          {/* <OrbitControls /> */}
        </Canvas>
      </div>
      </div>
      <ConfirmationSavedModal isOpen={showModal} onConfirm={()=>{setShowModal(false)}} onDecline={()=>{setShowModal(false)}}/>
      <WarningModal isOpen={showModalWarning} onConfirm={()=>{setShowModalWarning(false)}} message={messagesWarnings} buttonOkText ={"Renseigner les valeurs"}/>
    </div>

  )
}

import { useEffect, useMemo, useRef, useState } from "react";
import Count from "../count/index";
import CoverModal from "./fire-extinguisher-cover-modal";
import QuantitativeModal from "./fire-extinguisher-number-modal";
import Sidemenu from "./sidemenu";
import { useDisclosure } from "@nextui-org/react";
import { useSelector } from "react-redux";

export default function Verification () {
  const nbRef = useRef(null);
  const setNbRef = (node) => {
    nbRef.current = node;
  };
  const coverRef = useRef(null);
  const setCoverRef = (node) => {
    coverRef.current = node;
  };
  const [numberResultComputations, setNumberResultComputations] = useState(undefined);
  const [coverResultComputations, setCoverResultComputations] = useState(undefined);

  const [isQuantitativeVisible, setIsQuantitativeVisible] = useState(undefined);
  const [isCoverVisible, setIsCoverVisible] = useState(undefined);

  const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
  const hits = useSelector((state) => state.Comptage.Decompte.symbols);

  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    if (nbRef.current) {
      nbRef.current.style.display = "none";
    }

    if (coverRef.current) {
      coverRef.current.style.display = "none";
    }

    setNumberResultComputations(undefined);
    setCoverResultComputations(undefined);
  }, [hits, symbols]);

  useEffect(() => {
    if (nbRef.current) {
      nbRef.current.style.display = numberResultComputations === undefined ? "none" : "";
    }
    if (coverRef.current) {
      coverRef.current.style.display = coverResultComputations === undefined ? "none" : "";
    }
  }, [numberResultComputations, coverResultComputations]);

  const showExtinctorsNumber = () => {
    if (numberResultComputations !== undefined) {
      openNumberResult(numberResultComputations);
    }
  };

  const showCover = () => {
    if (coverResultComputations !== undefined) {
      openCoverResult(coverResultComputations);
    }
  };

  const openNumberResult = (result) => {
    setNumberResultComputations(result);
    setIsQuantitativeVisible(true);
    setIsCoverVisible(false);
    onOpen();
  };

  const openCoverResult = (result) => {
    setCoverResultComputations(result);
    setIsQuantitativeVisible(false);
    setIsCoverVisible(true);
    onOpen();
  };

  return (
    <>
      <Count
        sidemenus={[{
          title: "UxellOGS",
          element: <Sidemenu
            nbRef={setNbRef}
            coverRef={setCoverRef}
            showExtinctorsNumber={showExtinctorsNumber}
            showCover={showCover}
            openNumberResult={openNumberResult}
            openCoverResult={openCoverResult}
          />
        }]}
      >
        {isQuantitativeVisible && <QuantitativeModal
          resultComputations={numberResultComputations}
          size="5xl"
          isOpen={isOpen} 
          onClose={onClose}
        />}
        {isCoverVisible && <CoverModal
          resultComputations={coverResultComputations}
          size="5xl"
          isOpen={isOpen} 
          onClose={onClose}
        />}
      </Count>
    </>
  );
};

"use client";

import React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
} from "@nextui-org/react";

import ContactComponent from ".";

export default function ContactModal({isOpen, onOpen, onOpenChange}) {
  return (
      <Modal isOpen={isOpen} shouldBlockScroll={false} scrollBehavior={"outside"} onOpenChange={onOpenChange} className="w-fit max-w-fit p-6">
        <ModalContent>
          {(onClose) => (
            <ModalBody>
              <ContactComponent showHome={false} />
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
  );
}

import { Chip } from "@nextui-org/react";
import { domAnimation, LazyMotion, m } from "framer-motion";
import React, { useEffect, useMemo,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, Navigate, useOutletContext } from "react-router-dom";
import { setGeoData } from "../../../../store/features/PYM/GeoSlice";
import RedirectionModal from "../components/redirection-modal";
import EditableTable from "../../../../components/table/editable-table";
import config from "../../../../config";
import { updateLightsData } from "../../../../store/features/PYM/GeoSlice";
import LeafletMap from "../map";
import Voiries from "../voiries";
import MultistepSidebar from "./multistep-sidebar";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";

const variants = {
  enter: (direction) => ({
    y: direction > 0 ? 30 : -30,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    zIndex: 0,
    y: direction < 0 ? 30 : -30,
    opacity: 0,
  }),
};

const statusColorMap = {
  good: "success",
  bad: "danger",
};

export default function Calculs() {
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  // Declaration des states
  const { launch_request } = useAzureFuncApp();

  const [[page, direction], setPage] = React.useState([0, 0]);
  const [plotPath, setPlotPath] = React.useState("");
  const navigate = useNavigate();


  const projectInfos = useSelector((state) => state.PYM.ProjectInfos);
  const geoDataInfos = useSelector((state) => state.PYM.GeoData);
  const dispatch = useDispatch();

  const [lightsData, setLightsData] = React.useState(useSelector((state) => state.PYM.GeoData.city_lights_data));
  const [lightsFiles, setLightsFiles] = React.useState(useSelector((state) => state.PYM.GeoData.city_lights_files));
  const [tableData, setTableData] = React.useState(lightsData);
  const [filters, setFilters] = React.useState([]);
  const [showModal,setShowModal] = useState(false);
  const [calculationZone,setCalculationZone] = useState([])

  const allStatusGood = useMemo(() => {
    return tableData.every(x => x.properties.status === "good");
  }, [tableData]);

  // Gestion des comportements
  useEffect(() => {
    if (page === 2) {
      launchSimulation();
    }
  }, [page])

  useEffect(()=>{
    if(!lightsFiles || lightsFiles.length ===0){
      setShowModal(true);
    }
  },[lightsFiles])

  var initial_visible_columns = useMemo(() => { 
    return [
      {
        name: "Code",
        uid: "code",
        valueAccessor: (item) => item.properties.code,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, code: value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
      },
      {
        name: "Source",
        uid: "source",
        valueAccessor: (item) => item.properties.source,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, source: value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
      },
      {
        name: "Fichier",
        uid: "fichier",
        valueAccessor: (item) => item.properties.fichier,
        valueMutator: (item, value) => {
          console.log('value',value)
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, fichier: value };
          return newItem;
        },
        isEditable: true,
        type: 'list',
        listItems: lightsFiles,
        width: "500px",
        onValueChange: (item) => handleOnValueChange(item)
      },
      {
        name: "Hauteur",
        uid: "hauteur",
        valueAccessor: (item) => item.properties.hauteur,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, hauteur: value };
          return newItem;
        },
        isEditable: true,
        type: 'number',
        width: "500px",
      },

      {
        name: "Status",
        uid: "status",
        valueAccessor: (item) => item.properties.status,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, status: value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
        renderCell: (item) => {
          return (<Chip
            className="capitalize border-none gap-1 text-default-600 flex flex-row"
            color={statusColorMap[item.properties.status]}
            size="sm"
            variant="dot"
          ></Chip>)
        }
      },
    ];
  }, [tableData]);


  async function launchSimulation() {

    const payload = {
      lightsData: geoDataInfos.city_lights_data,
      roadsData: geoDataInfos.roads_data,
      zoneCalcul : calculationZone,
      pathFile: projectInfos.project_path,
    };

    setLoadingMessage("Simulation des éclairages...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymSimulation,
      payload
    );
      console.log('payload',payload)
    if (status_code === 200) {
      setPlotPath(result["result"])
      let dataLights = await fetch(result["dataUrlLight"]).then((response) =>
        response.json());
    } else {
      console.error("Erreur dans la réponse :", status_code);
    }
    setIsLoading(false);
  }

  const handleOnValueChange = (item) => {
    const fichier = item.properties.fichier;
    let newItem = { ...item };
  
    let value = typeof fichier === "string" ? fichier : fichier.length > 0 ? fichier[0] : "";
    let newStatus = (value === "" || value === "inconnu") ? "bad" : "good";
    newItem.properties = { ...newItem.properties, status: newStatus };

    return newItem;
  }

  const paginate = React.useCallback((newDirection) => {
    setPage((prev) => {
      const nextPage = prev[0] + newDirection;
      if (nextPage < 0 || nextPage > 3) return prev;
      return [nextPage, newDirection];
    });
  }, []);
  const onChangePage = React.useCallback((newPage) => {
    setPage((prev) => {
      if (newPage < 0 || newPage > 3) return prev;
      const currentPage = prev[0];

      return [newPage, newPage > currentPage ? 1 : -1];
    });
  }, []);
  const onBack = React.useCallback(() => {
    paginate(-1);
  }, [paginate]);
  const onNext = React.useCallback(() => {
    paginate(1);
  }, [paginate]);

  return (
    <>
      <MultistepSidebar
        currentPage={page}
        onBack={onBack}
        onChangePage={onChangePage}
        onNext={onNext}
        allStatusGood={allStatusGood}
      >
        <div className="relative flex h-fit w-full flex-col pt-6 text-center lg:h-full lg:pt-0">
          <LazyMotion features={domAnimation}>
            <m.div
              key={page}
              animate="center"
              className="col-span-12 h-full w-full"
              custom={direction}
              exit="exit"
              initial="exit"
              transition={{
                y: {
                  ease: "backOut",
                  duration: 0.35,
                },
                opacity: { duration: 0.4 },
              }}
              variants={variants}
            >
              {page === 0 && 
              <EditableTable
                columns={initial_visible_columns}
                data={tableData}
                // defaultGroupedColumns={["source", "hauteur"]}
                setData={setTableData}
                reduxTableSaveAction={updateLightsData}
                pagination={true}
                showTopRibbon={true}
                filters={filters}
                rowsPerPage={10} />}
              {page === 1 && <LeafletMap onRectangleCoordsChange={(coords) =>{setCalculationZone(coords)}} show_roads={true} show_tools={true} />}
              {/* {page === 2 && <Voiries needFilterSideBar={false} needAllRoads={geoDataInfos.selected_roads === undefined} />} */}
              {page === 2 && <LeafletMap needPlot={true} plotPath={plotPath} />}
            </m.div>
          </LazyMotion>
        </div>
        <RedirectionModal isOpen={showModal} onConfirm={()=>{
          setShowModal(false);
          navigate(`/pymmap/fichiers-ies-ldt`);

        }} 
        onDecline={
          ()=>{
          setShowModal(false);
            navigate(`/pymmap/accueil`)
          }
        }
        message ={<>Il semble qu’<b>aucun</b> fichier photométrique ne soit actuellement associé au <b>projet</b> en cours.</>} 
        buttonOkText={"Ajouter des fichiers"}
      /> 
      </MultistepSidebar>
    </>
  );
}

import { lazy } from "react";
import ProtectedRoute from "../components/login/protected-route";

//routes services
const PDF = lazy(() => import("../pages/services/PDF/index"));
const PDFToImage = lazy(() => import("../pages/services/PDF/pdf_en_image"));
const DiviserPDF = lazy(() => import("../pages/services/PDF/diviser_pdf"));
const ImageToPDF = lazy(() => import("../pages/services/PDF/image_en_pdf"));
const FusionPDF = lazy(() => import("../pages/services/PDF/fusion_pdf"));
const OcrPDF = lazy(() => import("../pages/services/PDF/ocr"));
const UnlockPDF = lazy(() => import("../pages/services/PDF/unlock_pdf"));
const FiligranePDF = lazy(() => import("../pages/services/PDF/filigrane"));
const PDF2Revit = lazy(() => import("../pages/services/PDF/PDF2Revit/index"));

const PdfRoutes = {
  path: "/",
  element: "",
  children: [
        {
          path: "/pdf",
          element: (
            <ProtectedRoute roles={["baseauth", "Plateforme.Service13", "Plateforme.FullAccess"]}>
              <PDF />
            </ProtectedRoute>
          ),
        },
        {
          path: "/pdf/pdf_vers_image",
          element: (
            <ProtectedRoute roles={["baseauth", "Plateforme.Service13", "Plateforme.FullAccess"]}>
              <PDFToImage />
            </ProtectedRoute>
          ),
        },
        {
          path: "/pdf/diviser",
          element: (
            <ProtectedRoute roles={["baseauth", "Plateforme.Service13", "Plateforme.FullAccess"]}>
              <DiviserPDF />
            </ProtectedRoute>
          ),
        },
        {
          path: "/pdf/image_vers_pdf",
          element: (
            <ProtectedRoute roles={["baseauth", "Plateforme.Service13", "Plateforme.FullAccess"]}>
              <ImageToPDF />
            </ProtectedRoute>
          ),
        },
        {
          path: "/pdf/fusionner",
          element: (
            <ProtectedRoute roles={["baseauth", "Plateforme.Service13", "Plateforme.FullAccess"]}>
              <FusionPDF />
            </ProtectedRoute>
          ),
        },
        {
          path: "/pdf/ocr",
          element: (
            <ProtectedRoute roles={["baseauth", "Plateforme.Service13", "Plateforme.FullAccess"]}>
              <OcrPDF />
            </ProtectedRoute>
          ),
        },
        {
          path: "/pdf/deverrouillage",
          element: (
            <ProtectedRoute roles={["baseauth", "Plateforme.Service13", "Plateforme.FullAccess"]}>
              <UnlockPDF />
            </ProtectedRoute>
          ),
        },
        {
          path: "/pdf/filigrane",
          element: (
            <ProtectedRoute roles={["baseauth", "Plateforme.Service13", "Plateforme.FullAccess"]}>
              <FiligranePDF />
            </ProtectedRoute>
          ),
        },
        {
          path: "/pdf/pdf2revit",
          element: (
            <ProtectedRoute roles={["Plateforme.Service15", "Plateforme.FullAccess"]}>
              <PDF2Revit />
            </ProtectedRoute>
          ),
        }
  ],
};
export default PdfRoutes;

const environment = process.env.REACT_APP_ENVIRONMENT.trim();

const API_DENOMBREMENT_URI      = environment === "production" || environment === "local" ? process.env.REACT_APP_API_DENOMBREMENT_URI : process.env.REACT_APP_API_DENOMBREMENT_URI_TEST;
const API_DIANE_PLATEFORME_URI  = environment === "production" || environment === "local" ? process.env.REACT_APP_API_DIANE_PLATEFORME_URI : process.env.REACT_APP_API_DIANE_PLATEFORME_URI_TEST;
const API_DIANE_INTERNAL_URI    = environment === "production" || environment === "local" ? process.env.REACT_APP_API_DIANE_INTERNAL_URI : process.env.REACT_APP_API_DIANE_INTERNAL_URI_TEST;
const API_OMIA_URI              = environment === "production" || environment === "local" ? process.env.REACT_APP_API_OMIA_URI : process.env.REACT_APP_API_OMIA_URI_TEST;
const API_PYM_URI               = environment === "production" || environment === "local" ? process.env.REACT_APP_API_PYM_URI : process.env.REACT_APP_API_PYM_URI_TEST;
const API_ALLYSIA_URI           = environment === "production" || environment === "local" ? process.env.REACT_APP_API_ALLYSIA_URI : process.env.REACT_APP_API_ALLYSIA_URI_TEST;
const API_SEGMENTATION_URI      = environment === "production" || environment === "local" ? process.env.REACT_APP_API_SEGMENTATION_URI : process.env.REACT_APP_API_SEGMENTATION_URI_TEST;

const config = {
  isLocal: environment === "local",
  isDebug: environment === "local" || environment === "development",

  apiBlobManagementUri: "/api/blobs",
  apiCredentialsUri: "/api/credentials",
  apiErrorReportUri: "/api/errorreport",
  apiAccessUri:"/api/pushaccess",
  apiToolsUri:"/api/gettools",
  apiGetRolesUri:"/api/getroles",
  apiSaveFeedbacks:"/api/savefeedback",

  apiCountItemsUri: `${API_DENOMBREMENT_URI}/CountItems`,
  apiFastsamCountUri: `${API_DENOMBREMENT_URI}/fastsam_count`,
  apiSegmentationUri: `${API_SEGMENTATION_URI}/denombrement/segment_items`,
  apiUxellOGSUri: `${API_DENOMBREMENT_URI}/Fabecrea`,
  apiMetreUri: `${API_DENOMBREMENT_URI}/Metre`,

  apiApproUxelloUri: `${API_DIANE_PLATEFORME_URI}/appro`,
  apiC2CUri: `${API_DIANE_PLATEFORME_URI}/c2c`,
  apiChatbotUri: `${API_DIANE_PLATEFORME_URI}/Chatbot`,
  apiDifferenceIndexUri: `${API_DIANE_PLATEFORME_URI}/DifferenceIndexBlueprint`,
  apiElioveUri: `${API_DIANE_PLATEFORME_URI}/eliove`,
  apiPDF2RevitUri: `${API_DIANE_PLATEFORME_URI}/Pdf2Revit`,
  apiPdfTextDifferenceUri: `${API_DIANE_PLATEFORME_URI}/PdfTextDifference`,
  apiPdfUri: `${API_DIANE_PLATEFORME_URI}/Pdf`,
  apiSendEmailUri: `${API_DIANE_PLATEFORME_URI}/SendEmailDiane`,
  apiVtidf: `${API_DIANE_PLATEFORME_URI}/Vtidf`,
  apiClauseControl: `${API_DIANE_PLATEFORME_URI}/ClauseControl`,
  
  apiContracts: `${API_DIANE_INTERNAL_URI}/contrats`,
  
  apiVfMaintenance: `${API_OMIA_URI}/VFOffreMaintenance`,

  pymParseFile: `${API_PYM_URI}/parse_files`,
  pymGetLights: `${API_PYM_URI}/parse_files`,
  pymLoadSave: `${API_PYM_URI}/load_save`,
  pymDeleteSave: `${API_PYM_URI}/delete_save`,
  pymSimulation: `${API_PYM_URI}/simulation`,
  pymOptimisation: `${API_PYM_URI}/optimisation`,
  apiPymUri: `${API_PYM_URI}`,

  apiAllysiaUri: `${API_ALLYSIA_URI}/`

};
export default config;

import { useDispatch, useSelector } from "react-redux";
import { fabric } from 'fabric';
import { CanvasContext } from "./contexts/canvas-context";
import { addLayer, setActiveLayer, setLayers } from "../../store/components/PolygonEditor/ImageSlice";
import ImageView from "./components/imagePreview/imageView";
import { Pagination } from "@nextui-org/react";
import ReactPanZoom from "./components/pan-zoom-rotate-panel";
import { useContext, useEffect, useState } from "react";
import { useDrawShape } from "./contexts/draw-context";
import { v4 as uuidv4 } from 'uuid';
import CustomPagination from "../inputs/custom-pagination";

//https://github.com/n-mazaheri/image-editor


const PDFEditor = ({
  initialLayers = ["background"],
  page,
  setPage,
  toolsPanel=(<></>),
  visiblePages=undefined,
  pageCount=1,
  showScale=false,
  getImages=(page)=>{return [];},
  restoreShapes=(page)=>{}
}) => {
  const dispatch = useDispatch();
  const layers = useSelector((state) => state.image.layers);
  const layernames = useSelector((state) => state.image.layers.map(x => x.name));
  const backgroundLayer = useSelector((state) => state.image.layers.find(x=>x.name === "background"));
  const activeLayerId = useSelector((state) => state.image.activeLayer);
  const activeLayer = useSelector((state) => state.image.layers.find(x=>x.id === activeLayerId));
  const backgroundLayerId = useSelector((state) => {
    let background = state.image.layers.find(x=>x.name === "background");
    if (background) {
      return background.layerId;
    }
    else {
      return -1;
    }
  });

  const [oldPage, setOldPage] = useState(0);
  const { clearCanvas, currentScale, displayScale, setDisplayScale } = useContext(CanvasContext);
  const { insertImage, updateDisplayCalibration } = useDrawShape();
  
  useEffect(() => {
  }, [displayScale]);
  
  useEffect(() => {
    setDisplayScale(showScale);
    setPage(1);
    dispatch(setLayers([]));
    if (initialLayers) {
      dispatch(addLayer("background"));
      if(showScale===true) {
        dispatch(addLayer("scale_foreground_vpf"));
      }

      for (let l of initialLayers) {
        dispatch(addLayer(l));
      }
    }
  }, []);

  useEffect(() => {
    if (page !== 0 && oldPage !== page) {
      clearCanvas(false);
      
      updateDisplayCalibration();
      const imagesForPage = getImages(page-1);
      if (imagesForPage) {
        for (let data of imagesForPage) {
          const id = uuidv4(); // Generate unique ID for new shape
          
          insertImage(data.url, data.x, data.y, {
            originX: "left",
            originY: "top",
            scale: 1,
            layer: backgroundLayerId,
            selectable: false,
            fixed: true,
            visible: true,
            isBackground: true,
            id: id
          });
        }
      }
      if (initialLayers.every(x => layernames.includes(x))) {
        setOldPage(page);
        restoreShapes(page);
      }
    }
  }, [page, layernames.length]);
  

  useEffect(() => {
    updateDisplayCalibration();
  }, [currentScale]);

  return (<>
    {/* Flex container to layout ImageView and SideMenu horizontally */}
    <div className="flex w-full h-full overflow-hidden">
      {/* Container for ImageView component */}
      <ImageView
        backgroundColor="#D3D3D3"
      />
    </div>

    <div className="top-2 left-2 absolute">
        <ReactPanZoom />
    </div>
    
    <div className="w-full bottom-2 absolute overflow-hidden flex justify-center">
        <CustomPagination
          isCompact
          showControls
          visiblePages={visiblePages}
          total={pageCount}
          onChange={setPage}
        />
    </div>

    {toolsPanel}
  </>);
}

export default PDFEditor;
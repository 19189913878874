import ReactDOM from 'react-dom/client';
import DianeModal from './DianeModal';
import { Button, useDisclosure } from '@nextui-org/react';
import React from 'react';

export const DianeYesNoModal = ({ title, body, onYes, onNo, onClose, yesText, noText, yesButtonProps, noButtonProps, modalRoot, ...props }) => {
    const { isOpen, onClose: onCloseModal } = useDisclosure({ defaultOpen: true });

    const handleYes = () => {
        if (onYes) onYes();
        handleClose();
    };

    const handleNo = () => {
        if (onNo) onNo();
        handleClose();
    };

    const handleClose = () => {
        if (onClose) onClose();
        onCloseModal();

        if (modalRoot) {
            document.body.removeChild(modalRoot);
        }
    };

    return (
        <DianeModal
            title={title}
            body={body}
            placement="top"
            size="sm"
            footer={
                <>
                    <Button 
                        color="default"
                        variant='flat' 
                        onClick={handleNo} 
                        {...noButtonProps}
                    >
                        {noText}
                    </Button>
                    <Button 
                        color="primary"
                        variant='flat'
                        onClick={handleYes} 
                        {...yesButtonProps}
                    >
                        {yesText}
                    </Button>
                </>
            }
            isOpen={isOpen}
            onClose={handleClose}
            modalRoot={modalRoot}
            {...props}
        />
    );
};

export default function YesNoAlert({
    title,
    body,
    yesText = 'Confirmer', 
    noText = 'Annuler', 
    onYes, 
    onNo, 
    yesButtonProps = {}, 
    noButtonProps = {}, 
    ...props 
}) {
    // Create a new div to hold the modal
    const modalRoot = document.createElement('div');
    document.body.appendChild(modalRoot);


    // Render the modal into the created div
    ReactDOM.createRoot(modalRoot).render(
        <DianeYesNoModal
            title={title}
            body={body}
            onYes={onYes}
            onNo={onNo}
            yesText={yesText}
            noText={noText}
            yesButtonProps={yesButtonProps}
            noButtonProps={noButtonProps}
            {...props}
        />
    );
}

import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { graphConfig, graphScope, funcAppScope } from './auth-config';
import config from './config';
import { useMsal } from '@azure/msal-react';

const EntraUserContext = createContext();

export const EntraUserProvider = ({ children }) => {
    const { instance, accounts } = useMsal();

    const [graphUserData, setGraphUserData] = useState(null);
    const [userPhoto, setUserPhoto] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    
    const userData = useMemo(() => {
        if (!graphUserData) return null;

        return {
            ...graphUserData,
            name: graphUserData.displayName,
            firstname: graphUserData.givenName,
            lastname: graphUserData.surname,
            email: graphUserData.mail,
            email_nickname: graphUserData.mail.split("@")[0]
        };
    }, [graphUserData]);

    const isAuthenticated = useMemo(() => {
        return userData !== null;
    }, [userData]);

    const [isLoading, setIsLoading] = useState(true);

    async function callGraph(accessToken, endpoint) {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
    
        headers.append("Authorization", bearer);
    
        const options = {
            method: "GET",
            headers: headers
        };
    
        return fetch(endpoint, options)
            .then(response => response.json())
            .catch(error => console.error(error));
    }

    async function callGraphPhoto(accessToken, endpoint) {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
    
        headers.append("Authorization", bearer);
    
        const options = {
            method: "GET",
            headers: headers
        };
    
        return fetch(endpoint, options)
            .then(response => response.blob())
            .catch(error => console.error(error));
    }

    const requestUserData = async () => {
        setIsLoading(true);
        const response = await instance.acquireTokenSilent({
            ...graphScope,
            account: accounts[0],
        });
        const data = await callGraph(response.accessToken, graphConfig.graphMeEndpoint);
        setGraphUserData(data);
    };

    const requestUserPhoto = async () => {
        const response = await instance.acquireTokenSilent({
            ...graphScope,
            account: accounts[0],
        });
        const data = await callGraphPhoto(response.accessToken, graphConfig.graphPhotoEndpoint);
        setUserPhoto(URL.createObjectURL(data));
    };

    const requestRoles = async () => {
        const response = await instance.acquireTokenSilent({
            ...graphScope,
            account: accounts[0],
        });

        const accessToken = await instance.acquireTokenSilent({
            ...funcAppScope,
            account: accounts[0],
        });
        
        let headers = {
            Authorization: `Bearer ${accessToken.accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
                "Content-Type": "text/plain",
        };

        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                accessToken: response.accessToken
            })
        };
        try {
            const responseRoles = await fetch(config.apiGetRolesUri, options);
            if (responseRoles.status === 200) {
                let result = await responseRoles.json();
                setUserRoles(result.roles);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const getGraphToken = async () => {
        const response = await instance.acquireTokenSilent({
            ...graphScope,
            account: accounts[0],
        });

        return response.accessToken;
    };

    useEffect(() => {
        async function init() {
            await requestUserData();
            await requestRoles();
            setIsLoading(false);
            requestUserPhoto();
        }

        init();
    }, []);

    return (
        <EntraUserContext.Provider value={{
            userData,
            userPhoto,
            userRoles,
            isAuthenticated,
            isLoading,
            getGraphToken
        }}>
            {children}
        </EntraUserContext.Provider>
    );
};

export const useEntraUser = () => {
    return useContext(EntraUserContext);
};

import { Card, CardFooter } from "@nextui-org/card";
import { Image } from "@nextui-org/react";
import { useEffect, useState } from "react";

const CheckableImage = ({ key=undefined, src, alt, text, onValueChange, isSelected, className="" }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (onValueChange !== undefined) {
      onValueChange(!isChecked);
    }
  };

  useEffect(() => {
    setIsChecked(isSelected);
  }, [isSelected]);

  return (
    <Card
      isPressable
      onPress={handleCheckboxChange}
      disableAnimation
      className={`flex flex-col p-1 ${className}`}
      classNames={{
        base: isSelected ? "border-danger border-solid border-3 border-opacity-40" : "border-transparent"
      }}
    >
      <img
          src={src}
          alt={alt}
          className="object-contain w-full h-full flex-1 overflow-hidden"
      />
      <CardFooter className="p-0">
        <label className="mx-auto h5 subtitle">{text}</label>
      </CardFooter>
    </Card>
  );
};

export default CheckableImage;
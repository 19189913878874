import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from '../../../../components/polygon-editor/components/sideMenu/sideMenu.module.css';
import EditableTable from '../../../../components/table/editable-table';
import classNames from 'classnames';
import { Button, Chip, Image, Select, SelectItem, Slider, Spacer, Switch, Tooltip } from '@nextui-org/react';
import {Icon} from "@iconify/react";
import { useDispatch, useSelector } from 'react-redux';
import { switchSymbolVisibility, switchAllSymbolsVisibility, updateSymbols } from '../../../../store/features/Comptage/configuration';
import { isPointInPolygon } from '../../../../utils/geometry';
import { Functions } from './functions';
import YesNoAlert from '../../../../components/modals/AlertYesNo';

export default function SideMenu({
  setIsLoading,
  setLoadingMessage,
  activeFunction,
  setActiveFunction,
  countManual,
  countAuto,
  countAll,
  deleteItem,
  deleteAllItems,
  page,
  currentSymbol,
  currentSymbolData,
  setCurrentSymbol,
  currentZones,
  setCurrentZones,
  confidenceRate,
  setConfidenceRate,
  inputPointSize,
  setInputPointSize,
  alpha,
  setAlpha,
  countInZones,
  setCountInZones,
  sidemenus
}) {
  const [, setRender] = useState(0); // Create a state that you don't use, just update
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(true);
  const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
  const zones = useSelector((state) => state.Comptage.Configuration.zones.filter(x =>x.page === page));
  const countedItems = useSelector((state) => state.Comptage.Decompte.symbols.filter(x =>x.page === page));
  const [allSymbolsVisible, setAllSymbolsVisible] = useState(true);

  useEffect(() => {
    setAllSymbolsVisible(Object.values(symbols).every(s => s.isVisible === true));
  }, [symbols]);


  const data = useMemo(() => {
    if (currentSymbol === "all") {
      return symbols.map((x, idx) => {
        return {
          id: idx,
          photo: x.src,
          symbol: x.name,
          crop_id: x.crop_id,
          isVisible: x.isVisible,
          quantity: countedItems
                      .filter(i => x.confidence_rate === undefined || i.confidence_rate >= x.confidence_rate[0])
                      .filter(i => x.confidence_rate === undefined || i.confidence_rate <= x.confidence_rate[1])
                      .filter(i => i.crop_id === x.crop_id)
                      .length,
        };
      });
    } else {
      const itemsInZones = zones.map((x, idx) => {
        let items = countedItems
          .filter(i => i.confidence_rate >= confidenceRate[0])
          .filter(i => i.confidence_rate <= confidenceRate[1])
          .filter(i => i.crop_id === currentSymbol)
          .filter(i => isPointInPolygon(x.points, i));
        return {
          id: idx,
          zone: x.name,
          items: items,
          quantity: items.length,
        };
      });
      console.log(new Set(itemsInZones.map(x => x.items).flat()).length, new Set(itemsInZones.map(x => x.items).flat()))
      return itemsInZones.concat([{
        id: zones.length,
        zone: "Aucune zone",
        quantity: countedItems
                    .filter(i => i.confidence_rate >= confidenceRate[0])
                    .filter(i => i.confidence_rate <= confidenceRate[1])
                    .filter(i => i.crop_id === currentSymbol).length - new Set(itemsInZones.map(x => x.items).flat()).size
      }]);
    }
  }, [countedItems, currentSymbol, symbols, confidenceRate, zones]);

  const [filters, setFilters] = useState([]);

  const changeVisibilityItem = (item) => {
    dispatch(switchSymbolVisibility(item.crop_id));
  };

  const changeVisibilityAllItems = () => {
    dispatch(switchAllSymbolsVisibility(!allSymbolsVisible));
  };

  const columnsAll = useMemo(() => [{
    uid: "visibility",
    name: "",
    type: "image",
    isEditable: false,
    minWidth: "50px",
    width: "50px",
    maxWidth: "50px",
    renderCell: (item) => {
      return (<Icon
          onClick={() => changeVisibilityItem(item)}
          className="scale-[1.3] cursor-pointer"
          icon={(item.isVisible === undefined || item.isVisible) ? "codicon:eye" : "codicon:eye-closed"}
        />);
    }
  }, {
    uid: "photo",
    name: "",
    type: "image",
    isEditable: false
  }, {
    uid: "symbol",
    name: "Symbole",
    type: "text",
    isEditable: false
  }, {
    uid: "quantity",
    name: "Quantité",
    type: "int",
    isEditable: false
  }], []);

  const columnsItem = useMemo(() => [{
    uid: "zone",
    name: "Zone",
    type: "text",
    isEditable: false
  }, {
    uid: "quantity",
    name: "Quantité",
    type: "int",
    isEditable: false
  }], []);

  const [sidePanels, setSidePanels] = useState([]);

  useEffect(() => {
    let panels = [];

    if (sidemenus) {
      panels.push(...sidemenus.map(sm => ({
        ...sm,
        element: React.cloneElement(sm.element, {
          activeFunction: activeFunction,
          setActiveFunction: setActiveFunction,
          page: page,
          setIsLoading: setIsLoading,
          setLoadingMessage: setLoadingMessage
        })})
      ));
    }

    panels = panels.map((x, idx) => ({...x, id: idx, opened: false}));

    setSidePanels(panels);
  }, []);


  // Function to toggle the opened and visible states
  const toggleMenu = (id) => {
    console.log(id, opened)
    setOpened(id === -1);
    setSidePanels(sp => {
      return sp.map(x => ({...x, opened: x.id === id ? !x.opened : false}));
    });
  };

  const handleSelectedItemClick = (e) => {
    let key = e.target.getAttribute("data-key");
    if (key === "none") {
      setCurrentZones([]);
    }
    else if (key === "all") {
      setCurrentZones(zones.map(x => x.zone_id));
    }
    else {
      if (currentZones.find(x => x === key)) {
        setCurrentZones([...currentZones.filter(x => x !== key)]);
      }
      else {
        setCurrentZones([...currentZones, key]);
      }
    }
  };

  return (
    <div className={classNames(styles.parent, sidePanels.some(x=>x.opened) || opened ? styles.opened : styles.closed, "h-full")}>
      <div className='flex flex-row h-full max-h-full'>
        {/* Button to toggle the menu */}
        <div className={styles.openButton}>
          <div className={` ${styles.arrowParent} bg-white/80`} style={{
              padding: "20px 0px 20px 5px",
              borderRadius: "10px 0px 0px 10px",
              marginTop: "50px",
              writingMode: "tb",
              width: "100%",
            backgroundColor: "#f2f2f2"
          }} onClick={() => { toggleMenu(opened ? undefined : -1); }}>
            {/* Arrow icon to indicate open/close state */}
            <label className={`rotate-180 text-center cursor-pointer block ${opened ? "font-bold" : ""}`}>Compte</label>
          </div>
          {sidePanels.map(item => (<div className={`${styles.arrowParent} bg-white/80`} style={{
            padding: "20px 0px 20px 5px",
            borderRadius: "10px 0px 0px 10px",
            marginTop: "10px",
            writingMode: "tb",
            width: "100%",
            backgroundColor: "#f2f2f2"
          }} onClick={() => { toggleMenu(item.id); }}>
            {/* Arrow icon to indicate open/close state */}
            <label className={`rotate-180 text-center cursor-pointer block ${item.opened ? "font-bold" : ""}`}>{item.title}</label>
          </div>))}
        </div>

        <div style={{
            width:    opened ? "300px" : "",
            padding:  opened ? "" : "0",
            minWidth: opened ? "300px" : "",
            backgroundColor: "#f2f2f2"
          }} className='w-0 min-w-0 transition-all duration-700 linear h-full max-h-full px-4 py-3 overflow-y-auto'>
            <div className='flex flex-col h-full' style={{
              display: opened ? "" : "none"
            }}>
              <Select
                labelPlacement="outside"
                label="Sélectionnez un symbole"
                classNames={{
                  trigger: "!bg-default-300",
                  label: "top-[66%]"
                }}
                selectionMode='single'
                disallowEmptySelection={true}
                defaultSelectedKeys={["all"]}
                selectedKeys={[currentSymbol]}
                multiple={false}
                onSelectionChange={(key) => {
                  setCurrentSymbol(Array.from(key)[0]);
                }}>
                <SelectItem key="all">Tous</SelectItem>
                {symbols.map((s) => (
                  <SelectItem key={s.crop_id}>
                    {s.name}
                  </SelectItem>
                ))}
              </Select>
              <Select
                labelPlacement="outside"
                label="Sélectionnez les zones"
                selectionMode="multiple"
                classNames={{
                  trigger: "!bg-default-300",
                  label: "top-[66%]"
                }}
                disallowEmptySelection={true}
                defaultSelectedKeys={["all"]}
                selectedKeys={currentZones === undefined || currentZones.length === 0 ? ["none"] : (currentZones.length === zones.length ? ["all", ...currentZones] : currentZones)}
                renderValue={(items) => {
                  return (
                    <div className="flex flex-wrap gap-2">
                      {items.filter(x=>x.key === "all").length !== 0 ?
                        <span>Toutes</span>
                      : items.filter(x=>x.key === "none").length !== 0 ?
                        <span>Aucune</span>
                      : items.map((item) => (
                        <Chip key={item.key} color='danger'>{item.textValue}</Chip>
                      ))}
                    </div>
                  );
                }}>
                <SelectItem key="all" onPress={handleSelectedItemClick}>Toutes</SelectItem>
                <SelectItem key="none" onPress={handleSelectedItemClick}>Aucune</SelectItem>
                {zones.map((s) => (
                  <SelectItem key={s.zone_id} onPress={handleSelectedItemClick}>
                    {s.name}
                  </SelectItem>
                ))}
              </Select>
              <Spacer y={4}/>
              {currentSymbol === "all" && <div>
                <div className='flex flex-row mb-1 gap-2'>
                  <Button className='shadow-lg' onClick={deleteItem} color={activeFunction === Functions.DELETE_ITEM ? "danger" : "default"}>
                    <Icon icon="jam:rubber" className='scale-[1.5] flex-5' />
                    <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer</span>
                  </Button>                        

                  <Button className='shadow-lg' onClick={deleteAllItems} color={activeFunction === Functions.DELETE_ALL ? "danger" : "default"}>
                    <Icon icon="material-symbols:delete-outline" className='scale-[1.5] flex-5' />
                    <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer tout</span>
                  </Button>
                </div>
                <Spacer y={4} />
                <Button className='w-full shadow-lg glass validation' onClick={countAll}>
                  <Icon icon="fluent:search-sparkle-16-filled" className='scale-[1.5] flex-5' />
                  <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Comptage automatique</span>
                </Button>

                <Spacer y={4} />
                <div className='flex flex-col overflow-hidden'>
                  <div className='flex flex-row items-center'>
                    <div className={`${styles.header} h4 mr-4`}>Symboles</div>
                    <Icon
                      onClick={() => changeVisibilityAllItems()}
                      className="scale-[1.3] cursor-pointer"
                      icon={(allSymbolsVisible) ? "codicon:eye-closed" : "codicon:eye"}
                    />
                  </div>
                  <div className='p-1 rounded-lg'>
                    <EditableTable
                      data={data}
                      filters={filters}
                      columns={columnsAll}
                      showTopRibbon={false}
                      pagination={false}
                      removeWrapper
                      reduxTableSaveAction={updateSymbols}
                      className="overflow-y-auto overflow-x-hidden"
                      emptyContent={"Aucun symbole"}
                      tableBackground=""
                    />
                  </div>
                </div>
              </div>}
              {currentSymbol !== "all" && <div>
                <div className='flex flex-col items-center'>
                  <div className={`${styles.header} h4 text-center`}>Aperçu</div>
                  <Image className='max-h-[100px] object-contain mx-auto' src={symbols.find(x=>x.crop_id === currentSymbol).src} />
                  <Spacer y={2} />
                  <Switch size="sm" defaultSelected isSelected={currentSymbolData === undefined || currentSymbolData.isVisible} onValueChange={() => {
                    if (currentSymbol !== "all") {
                      dispatch(switchSymbolVisibility(currentSymbol));
                    }
                  }}>{currentSymbolData.isVisible === undefined || currentSymbolData.isVisible ? "Symbole visible" : "Symbole invisible"}</Switch>
                </div>
                <Spacer y={4}/>
                <div className='flex flex-col items-center'>
                  <div className={`${styles.header} h4 text-center`}>Ajouter des symboles</div>
                  <div>
                    <Tooltip placement='left' content="Placer manuellement votre symbole" color="danger" showArrow={true}>
                      <Button className='shadow-lg w-full' onClick={countManual} color={activeFunction === Functions.MANUAL ? "danger" : "default"}>
                        <Icon icon="material-symbols:point-scan" className='scale-150' />Manuel
                      </Button>
                    </Tooltip>
                    <Spacer y={2} />
                    <div className='flex flex-row mb-1 gap-2'>
                      <Tooltip placement='left' content="Rechercher à partir des géométries présentes sur le fichier" color="danger" showArrow={true}>
                        <Button className='shadow-lg flex' onClick={() => {countAuto("vector", true)}} color={activeFunction === Functions.AUTO_VECTOR ? "danger" : "default"}>
                          <Icon icon="fluent:search-sparkle-16-filled" className='scale-[2.5] flex-5' /><span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Recherche IA (géométrie)</span>
                        </Button>
                      </Tooltip>
                      <Tooltip placement='left' content="Rechercher à partir de l'analyse d'image" color='danger' showArrow={true}>
                        <Button className='shadow-lg' onClick={() => {
                          YesNoAlert({
                            title: <div className='flex items-center gap-x-4'><Icon icon="basil:sand-watch-solid" className="scale-150" /><span>Rotation</span></div>,
                            body: <><p>Voulez-vous compter le symbole avec rotations ?</p><p>(Cela peut prendre beaucoup plus de temps)</p></>,
                            yesText: "Oui",
                            noText: "Non",
                            size: "md",
                            onYes: () => {
                              countAuto("image", true);
                            },
                            onNo: () => {
                              countAuto("image", false);
                            }
                          })}} color={activeFunction === Functions.AUTO_IMAGE ? "danger" : "default"}>
                          <Icon icon="ic:outline-image-search" className='scale-[2.5] flex-5' /><span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Recherche IA (image)</span>
                        </Button>
                      </Tooltip>
                    </div>
                    <Switch size="sm" defaultSelected isSelected={countInZones} onValueChange={setCountInZones}>Compter dans les zones visibles</Switch>
                    <Spacer y={2} />
                    <div className='flex flex-row mb-1 gap-2'>
                      <Button className='shadow-lg' onClick={deleteItem} color={activeFunction === Functions.DELETE_ITEM ? "danger" : "default"}>
                        <Icon icon="jam:rubber" className='scale-[1.5] flex-5' />
                        <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer</span>
                      </Button>
                      <Button className='shadow-lg' onClick={deleteAllItems} color={activeFunction === Functions.DELETE_ALL ? "danger" : "default"}>
                        <Icon icon="material-symbols:delete-outline" className='scale-[1.5] flex-5' />
                        <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer tout</span>
                      </Button>
                    </div>
                  </div>
                </div>
                <Spacer y={4}/>
                <div className='flex flex-col items-center w-full'>
                  <div className={`${styles.header} h4`}>Paramètres</div>
                  <div className='w-full'>
                    <Slider 
                      label="Seuil de confiance" 
                      step={0.01} 
                      size='sm'
                      maxValue={1} 
                      minValue={.3} 
                      defaultValue={confidenceRate}
                      value={confidenceRate}
                      className="max-w-md"
                      onChange={setConfidenceRate}
                    />
                    <Slider 
                      label="Taille des points" 
                      step={1} 
                      size='sm'
                      maxValue={50} 
                      minValue={1} 
                      defaultValue={inputPointSize}
                      value={inputPointSize}
                      className="max-w-md"
                      onChange={setInputPointSize}
                    />
                    <Slider 
                      label="Opacité des points" 
                      step={0.01} 
                      size='sm'
                      maxValue={1} 
                      minValue={0} 
                      defaultValue={alpha}
                      value={alpha}
                      className="max-w-md"
                      onChange={setAlpha}
                    />
                  </div>
                </div>
                <Spacer y={4}/>
                <div className='flex flex-col'>
                  <div className={`${styles.header} h4`}>Symboles</div>
                  <div className="p-1 rounded-lg flex max-h-full">
                    <EditableTable
                      data={data}
                      filters={filters}
                      columns={columnsItem}
                      showTopRibbon={false}
                      pagination={false}
                      removeWrapper
                      isHeaderSticky={true}
                      reduxTableSaveAction={updateSymbols}
                      className="overflow-y-auto"
                      emptyContent={"Aucun symbole"}
                      tableBackground=""
                    />
                </div>
              </div>
            </div>}
          </div>
        </div>
        {sidePanels.map(item => (<div style={{
          width:    item.opened ? "300px" : "",
          padding:  item.opened ? "" : "0",
          minWidth: item.opened ? "300px" : "",
          overflowY: "hidden",
          backgroundColor: "#f2f2f2"
        }} className='w-0 min-w-0 transition-all duration-700 linear h-full px-4 py-3'>
          <div className='flex flex-col h-full' style={{
            display: item.opened ? "" : "none"
          }}>
            {item.element}
          </div>
      </div>))}
    </div>
  </div>
  );
}

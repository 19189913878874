import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../../../config";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import RoadDisplayList from "./road-profil-display-list";
import LightsSelectionCard from "./lights-selection-card";
import {Tabs, Tab, Card, CardBody, CardHeader, Button, ResizablePanel} from "@nextui-org/react";
import ResultDisplay from "./result-display";
import { Icon } from "@iconify/react";
import RedirectionModal from "../components/redirection-modal";
import { Outlet, useNavigate, Navigate, useOutletContext } from "react-router-dom";

export default function Optimisation() {
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  const { launch_request } = useAzureFuncApp();
  const projectInfos = useSelector((state) => state.PYM.ProjectInfos);
  const projectGeoData = useSelector((state) => state.PYM.GeoData);
  const [roadsProfils, setRoadsProfils] = React.useState([]);
  const [isOptimised, setIsOptimised] = React.useState(false);
  const [selectedKey,setSelectedKey] = React.useState(0);

  const roadProfil = React.useMemo(() => {
    return roadsProfils.find(x=>x.id == selectedKey) || roadsProfils[0];
  }, [selectedKey]);
  const folderSelected = React.useMemo(() => {
    return roadsProfils.find(x=>x.id == selectedKey);
  }, [selectedKey]);
  const [tabs,setTabs] = React.useState([
    {
        id:projectGeoData.city_lights_files[0],
        label:projectGeoData.city_lights_files[0],
        content:<ResultDisplay/>
    }
  ]);

  const [selectedIES,setSelectedIES] = React.useState([projectGeoData.city_lights_files[0]])

const navigate = useNavigate();
const [showModal,setShowModal] = React.useState(false);
const [missRoadProfile,setMissRoadProfile] = React.useState(false);

  async function getRoadsProfile() {
    const payload = {
      functionName: "getRoadsProfiles",
      projectPath: projectInfos.project_path || "",
    };

    setLoadingMessage("Récupération des profils de route...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    if (status_code === 200) {
      if(result.roadsList.length ===0 || projectGeoData.city_lights_files.length ===0){
        setMissRoadProfile(result.roadsList.length ===0);
        setShowModal(true);
      }
  
      setIsLoading(false);
      return result.roadsList
    }
    setIsLoading(false);
  }
 
  async function handleOptimiseRoad(){
    const payload = {
      functionName: "optimiseRoadsProfiles",
      projectPath: projectInfos.project_path || "",
      roadProfile:roadProfil,
      iesSelected:selectedIES
    };

    if(selectedIES.length == 0 || roadProfil === undefined){
      return
    }

    console.log("Payload envoyé:", payload);
    setLoadingMessage("Optimisation des tronçons...")
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymOptimisation,
      payload
    );
    if (status_code === 200) {
      setIsLoading(false);
      setIsOptimised(true)
      setTabs((tabs) => {
        let newTabs = Array.from(Object.keys(result.result)).map((k) => ({
          id: k,
          label: k,
          content:<ResultDisplay data={result.result[k]}/>
        }))
        return newTabs
      })
    }  
    setIsLoading(false);
  }

  useEffect(() => {
    getRoadsProfile().then((result) => {
      setRoadsProfils(result.map((x, idx)=>({...x, id: idx})));
    });
  }, [])
  
    return(
        <div className="flex w-full h-full">
        <RoadDisplayList folderList={roadsProfils} className="lg:col-span-6 xl:col-span-4 flex flex-col py-4" folderSelected={folderSelected} selectedKey={selectedKey} setSelectedKey={setSelectedKey} />
        <div className="flex flex-col w-full h-full">
        {!isOptimised &&
            <div>
              <LightsSelectionCard lightsFiles ={projectGeoData.city_lights_files} setSelectedIES={setSelectedIES}/>
              <div className="flex justify-end mt-2 mr-2 ">
                <Button className='bg-[#80ed99]' onClick={()=>handleOptimiseRoad()}><Icon icon={'solar:calculator-line-duotone'}/><p className="text-small font-semibold text-default-foreground">Optimiser</p></Button>
              </div>
            </div>
            }
            {isOptimised &&
            <div className="w-full mt-4">
                <Tabs aria-label="Dynamic tabs" items={tabs}>
                    {(item) => (
                    <Tab key={item.id} title={item.label}>
                        <Card >
                        <CardBody>
                            {item.content}
                        </CardBody>
                        </Card>  
                    </Tab>
                    )}
                </Tabs>
            </div>  
            }
        </div>
        <RedirectionModal isOpen={showModal} onConfirm={()=>{
          setShowModal(false);
          let path = missRoadProfile ? `/pymmap/definition` : `/pymmap/fichiers-ies-ldt`
          navigate(path);
        }} 
        onDecline={
          ()=>{
          setShowModal(false);
            navigate(`/pymmap/accueil`)
          }
        }
        message ={<>Il semble qu’<b>aucun</b> {missRoadProfile ? "profil de route" : "fichier photométrique" } ne soit actuellement défini pour le <b>projet</b> en cours.</>
      } 
          buttonOkText = {`Ajouter des ${missRoadProfile ? 'profils' : 'fichiers'}`}
          /> 
        </div>
    )
}

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { clearFiles, setFiles } from "../../store/features/PDF/FileSlice";
import {
  Card,
  CardHeader,
  CardBody,
  Image,
  Spacer,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { DeleteIcon } from "../Icons";
import useAzureBlobs from "../../hooks/azure/useAzureBlobs";
import * as pdfjsLib from "pdfjs-dist";
import Alert from "../modals/Alert";
pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const FileLoader = ({
  path,
  fileType,
  multiple,
  maxFiles=Infinity,
  title,
  img,
  imgStyle,
  containerName,
  service,
  blobNamePrefix,
  onFileLoaded = (filename, arrayBuffer, totalPages, index) => {},
  handleFileChange,
  handleDelete,
  className,
  backgroundColor = "",
}) => {
  const { uploadFile } = useAzureBlobs();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const pdfFiles = useSelector((state) => state.file.files);
  const inputRef = useRef(null); // ajout d'une ref pour vider l'input a la fin

  useEffect(() => {
    dispatch(clearFiles());
  }, []);

  const getPageCount = async (file) => {
    const pdfData = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
    return pdf.numPages;
  };

  const addFiles = async (files) => {
    if (files.length > 0) {
      try {
        setIsLoading(true);
        let uploadedFiles = [];
        for (let i = 0; i < files.length; i++) {
          if (pdfFiles.length + uploadedFiles.length >= maxFiles) {
            break;
          }

          let totalPages = 0; 
          const file = files[i];
          const fileReader = new FileReader();
          const data = await new Promise((resolve) => {
            fileReader.onload = (e) => resolve(e.target.result);
            if (file.name.toLowerCase().endsWith(".pdf")) {
              fileReader.readAsArrayBuffer(file);
            } else if(file.name.toLowerCase().endsWith(".png") || file.name.toLowerCase().endsWith(".jpg") || file.name.toLowerCase().endsWith(".jpeg")) {
              fileReader.readAsDataURL(file);
            } else {
              resolve(file.name);
            }
          });
          const blobName = `${blobNamePrefix}${file.name}`;
          await uploadFile(containerName, blobName, file, service); 
          uploadedFiles.push(blobName);
          if (file.name.endsWith(".pdf")) {
            const numPages = await getPageCount(file);
            totalPages += numPages;
          }

          onFileLoaded(file.name, data, totalPages, i); // Passer le nombre de pages au parent
        }
        document.getElementById("upload-form").reset(); // Réinitialisez le formulaire ici

        let newPdfFiles = [];

        // Gestion du multiple
        if (multiple) {
          newPdfFiles = [...pdfFiles, ...uploadedFiles];
        } else {
          newPdfFiles = uploadedFiles;
        }
        
        dispatch(setFiles(newPdfFiles));
      } catch (error) {
        console.error("Error uploading files: ", error);
      }
      setIsLoading(false);
    }
  }
  if (handleFileChange === undefined){
    handleFileChange = async (files) => {
      addFiles(files);
    };
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  if (handleDelete === undefined){
    handleDelete = (index) => {
      const newPdfFiles = [...pdfFiles];
      newPdfFiles.splice(index, 1);
      dispatch(setFiles(newPdfFiles));
    };
  }

  function validateFileExtensions(files) {
    let allowedExtensions = fileType.split(',').map(x => x.trim().toLowerCase());

    let badFiles = files.filter(x => !allowedExtensions.some(ext => x.name.toLowerCase().endsWith(ext)));
    if (badFiles.length > 0) {
      Alert(`Certains fichiers sélectionnés n'ont pas pu être utilisés`, `${badFiles.map(x => x.name).join(", ")}`, { size: "lg"});
    }
    files = files.filter(x => allowedExtensions.some(ext => x.name.toLowerCase().endsWith(ext)));
    return files;
  }

  return (
    <div className={`max-w-4xl ${className !== undefined ? className : ""}`}>
      <LoadingOverlay
          active={isLoading}
          spinner={true}
          text={"Envoi des fichiers..."}
          className="w-full rounded-lg overflow-hidden"
        >
        <Card
          className="w-full glass light p-4"
          onDrop={(e) => {
            let files = Array.from(e.dataTransfer.files);
            e.preventDefault();
            files = validateFileExtensions(files);

            handleFileChange(files);
          }}
          onDragOver={handleDragOver}
          style={{ backgroundColor: backgroundColor }}
        > 
          <div style={{ display: "flex", alignItems: "center", justifyContent: (img !== undefined ? "space-between" : "center") }}>
            {img !== undefined && <Image src={img} style={imgStyle} className="max-w-[3vw]"></Image>}
            <h1 className={`font-bold text-xl ${(img === undefined ? "" : "mr-[50px]")} text-black text-center h3`}> {title}</h1>
            {img !== undefined && <div></div>}
          </div>
          <CardHeader className="justify-center px-6 pb-0">
            <div className="flex items-center text-black">
              <h3 className="text-center h5 subtitle">
                Déposez ou glissez vos fichiers :<br />( Fichiers supportés {fileType} )
              </h3>
            </div>
          </CardHeader>
          <CardBody>
            <Spacer y={1} />
            <form id="upload-form">
              <input
                className="upload-input"
                id="file_input"
                type="file"
                accept={fileType} // Modification pour accepter les fichiers PDF et Excel
                onChange={(e) => {
                  let files = Array.from(e.target.files);
                  files = validateFileExtensions(files);

                  handleFileChange(files);
                }}
                multiple={multiple}
                style={{ display: "none" }}
                ref={inputRef}
              />
            </form>

            <label
              htmlFor="file_input"
              className="upload-label px-2 py-2 inline-block text-center
                  bg-white text-[#CD062E] font-bold text-md
                  border-[#CD062E] border-2 rounded-lg
                  cursor-pointer"
            >
              {!multiple ? 'Déposez un fichier' 
                : maxFiles < 3 ? `Déposez ${maxFiles} ${maxFiles === 1 ? 'fichier' : 'fichiers'}` 
                : maxFiles < Infinity ? `Déposez entre 1 et ${maxFiles} fichiers`
                : 'Déposez des fichiers'}

            </label>
            <Spacer y={4} />
          </CardBody>
          {pdfFiles !== undefined && pdfFiles.length > 0 && (
            <Table aria-label="Liste des fichiers PDF">
              <TableHeader>
                <TableColumn>Nom du fichier</TableColumn>
                <TableColumn>Action</TableColumn>
              </TableHeader>
              <TableBody>
                {pdfFiles.map((filePath, index) => {
                  const fileName = filePath.split("/").pop();
                  return (
                    <TableRow key={index}>
                      <TableCell>{fileName}</TableCell>
                      <TableCell onClick={() => handleDelete(index)}>
                        <DeleteIcon size="small" color="error"></DeleteIcon>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Card>
      </LoadingOverlay>
    </div>
  );
};



export default FileLoader;

import { Card, CardBody, CardHeader, Button, Input, SelectItem, Image } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import React, { useMemo, useState } from "react";

export default function LightsDispositionCard({data, ...props }) {
  const [step, setStep] = React.useState(data["up"]['step']);
  const [lumHeight, setLumHeight] = React.useState(data['lum_height']);
  const [resultImg, setResultImg] = React.useState(data['up']['path']);

    const handleSelectionChange = (mode) =>{
            let r = data[mode]
            setStep(r["step"])
            setResultImg(r["path"])
    }
    return (
        <div className="flex flex-row h-full gap-4">
        <div className="flex h-full">
            <Card >
                <CardHeader className="flex flex-col gap-2 px-4 pb-4 pt-6">
                    <p className="text-medium text-content2-foreground">Disposition des luminaires</p>
                </CardHeader>
                <CardBody>
                    <div className="flex flex-row flex-nowrap justify-evenly items-center mb-4">
                        <Button className="h-[55px]" onClick={() =>{handleSelectionChange("up")}}>
                            <Image
                                style={{height:'45px'}}
                                alt={''}
                                className="aspect-square w-full"
                                src={'/images/pym/road-up.png'}
                            />
                        </Button>
                        <Button className="h-[55px]" onClick={() =>{handleSelectionChange("down")}}>
                            <Image
                                style={{height:'45px'}}
                                isBlurred
                                alt={''}
                                className="aspect-square w-full"
                                src={'/images/pym/road-down.png'}
                            />
                        </Button>
                        <Button className="h-[55px]" onClick={() =>{handleSelectionChange("both")}}>
                            <Image
                                style={{height:'45px'}}
                                isBlurred
                                alt={''}
                                className="aspect-square w-full"
                                src={'/images/pym/road-up-down.png'}
                            />
                        </Button>
                        <Button className="h-[55px]" onClick={() =>{handleSelectionChange("quinc")}}>
                            <Image
                                style={{height:'45px'}}
                                isBlurred
                                alt={''}
                                className="aspect-square w-full"
                                src={'/images/pym/road-quinconce.png'}
                            />
                        </Button>
                    </div>
                    <div>
                        <Input
                            className="my-4"
                            label="Espacement poteau (m)"
                            labelPlacement={'inside'}
                            errorMessage="Merci de renseigner une valeur numérique"
                            value={step}
                        />
                        <Input
                            className="my-4"
                            label="Hauteur des mats (m)"
                            labelPlacement={'inside'}
                            errorMessage="Merci de renseigner une valeur numérique"
                            value={lumHeight}
                        />
                    </div>
                </CardBody>
            </Card>
        </div>
        <div className="h-full w-full">
            <Card >
                <CardHeader className="flex flex-col gap-2 px-4 pb-4 pt-6">
                    <p className="text-medium text-content2-foreground">Resultat</p>
                </CardHeader>
                <CardBody>
                    <Image
                        // width={300}
                        className="flex"
                        alt="NextUI hero Image"
                        src= {resultImg}
                        />
                </CardBody>
            </Card>
        </div>
        </div>
        
    )
}
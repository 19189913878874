import { useState, useEffect } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { funcAppScope } from '../../auth-config';
import { useEntraUser } from '../../EntraUserProvider';
import config from '../../config';
import Alert from '../../components/modals/Alert';
import { Spacer } from '@nextui-org/react';

Date.prototype.yyyymmdd = function() {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();
  
    return [this.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd
           ].join('');
  };

Date.prototype.hhhmm = function() {
    var hh = this.getHours();
    var mm = this.getMinutes();

    return [(hh>9 ? '' : '0') + hh,
            (mm>9 ? '' : '0') + mm
            ].join('h');
};

const useAzureFuncApp = (baseURL=undefined) => {
    const { instance, accounts } = useMsal();
    const { userData } = useEntraUser();

    const acquireToken = async () => {
        const accessToken = await instance.acquireTokenSilent({
        ...funcAppScope,
        account: accounts[0],
        });
        return accessToken;
    };

    function get_browser_name() {
        const agent = window.navigator.userAgent.toLowerCase();
        const browser =
        agent.indexOf('edge') > -1 ? 'edge'
            : agent.indexOf('edg') > -1 ? 'chromium based edge'
            : agent.indexOf('opr') > -1 && window.opr ? 'opera'
            : agent.indexOf('chrome') > -1 && window.chrome ? 'chrome'
            : agent.indexOf('trident') > -1 ? 'ie'
            : agent.indexOf('firefox') > -1 ? 'firefox'
            : agent.indexOf('safari') > -1 ? 'safari'
            : 'other';

        return browser;
    }

    const launch_request = async (
        url,
        payload,
        {
            failure_message = "Erreur du script en ligne...",
            ignore_loading = false,
            method = "POST",
            ...kwargs
        } = {}
    ) => {
        let result = undefined;
        let status_code = 500;
        const accessToken = await acquireToken();
        
        var date = new Date();
        payload["user_data"] = userData;
        payload["navigator"] = get_browser_name();
        payload["date"] = date.yyyymmdd();
        payload["time"] = date.hhhmm();
        payload["is_debug"] = config !== undefined ? config["isDebug"] : false;

        try {
            let res = await axios({
                method: method,
                headers: {
                    Authorization: `Bearer ${accessToken.accessToken}`,
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "text/plain",
                },
                mode: "no-cors",
                data: JSON.stringify(payload),
                url: url,
                timeout: 0,
            });
        
            result = await res.data;
            status_code = 200;
        } catch (ex) {
            console.error(ex);
            if (
                ex !== undefined &&
                ex.response !== undefined
            ) {
                status_code = ex.response.status;
                let msg = <div>
                    <p>{failure_message}</p>
                    <Spacer y={2} />
                    <label className='text-sm italic underline'>Détails :</label>
                    <p className='text-sm'>{ex.response.data.user_message}</p>
                </div>;
                if (status_code === 520) {
                    msg = ex.response.data["user_message"];
                };
                Alert(`Erreur ${status_code}`, msg);
            } else {
                Alert("Erreur", failure_message);
            }
        }
        return [status_code, result];
    }

    const axiosClient = axios.create({
        baseURL: baseURL,
    });

    axiosClient.interceptors.request.use(async (axiosConfig) => {
        try {
            const accessToken = await acquireToken();
            const date = new Date();
            axiosConfig.headers.Authorization = `Bearer ${accessToken.accessToken}`;
            axiosConfig.headers.logs = encodeURIComponent(JSON.stringify({
                'user_data': userData !== undefined ? encodeURIComponent(JSON.stringify(userData)):"blabla",
                'navigator': get_browser_name(),
                'date': date.yyyymmdd(),
                'time': date.hhhmm(),
                'is_debug': config !== undefined ? config["isDebug"] : false
            }));
        } catch (error) {
            throw error;
        }
        return axiosConfig;
    }, (error) => {
        return Promise.reject(error);
    });

    axiosClient.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        return Promise.reject(error);
    });
    
    return { launch_request, axiosClient };
};

export default useAzureFuncApp;

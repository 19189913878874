import ReactDOM from 'react-dom/client';
import DianeModal from "./DianeModal";
import { Button, useDisclosure } from "@nextui-org/react";
import React, { useState } from 'react';

export default function Alert(title, body, props = {}) {
    // Simply call this function to display a modal alert
    const modalRoot = document.createElement('div');
    document.body.appendChild(modalRoot);

    const DianeModalAlert = ({ title, body, ...props }) => {
        const { isOpen, onClose } = useDisclosure({ defaultOpen: true });

        return (
            <DianeModal
                title={title}
                body={body}
                placement='top'
                size='sm'
                footer={
                    <Button 
                        color='default'
                        variant='flat'
                        onClick={onClose}
                    >Fermer</Button>
                }
                isOpen={isOpen}
                onClose={() => {
                    onClose();
                    document.body.removeChild(modalRoot);
                }}
                {...props}

            />
        );
    };

    ReactDOM.createRoot(modalRoot).render(<DianeModalAlert title={title} body={body} {...props} />);
}



import { Checkbox, ScrollShadow, Autocomplete, AutocompleteItem, Image } from "@nextui-org/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CheckableImage from "../../../../components/inputs/checkable-image";

const SymbolSelector = ({symbols, setSymbols, selectedSymbols, setSelectedSymbols, legendImage}) => {

    const allSymbolsSelected = useMemo(() => {
        return symbols.length === selectedSymbols.length;
    }, [selectedSymbols, symbols]);

    const handleCheckboxClick = useCallback((isSelected, id) => {
      if (isSelected) {
        setSelectedSymbols(s => {
          let newS = [...s, id];
          return newS;
        });
      } else{
        setSelectedSymbols(s => {
          let newS = [...s].filter(x => x !== id);
          return newS;
        });
      }
    }, [setSelectedSymbols]);

    const selectUnselect = (value) => {
      if (value) {
        setSelectedSymbols(symbols.map((s) => s.id));
      } else {
        setSelectedSymbols([]);
      }
    };

    const updateLabel = useCallback((value, id) => {
      setSymbols(prevSymbols => {
        const newSymbols = prevSymbols.map(s => 
          s.id === id ? { ...s, name: value } : s
        );
        return newSymbols;
      });
    }, [symbols, setSymbols]);

    const getSymbolDiv = useCallback((s) => {
      return <div className="flex flex-row" key={s.id}>
                <div key={s.id} className="flex w-full gap-4 items-center">
                <CheckableImage
                    key={s.id}
                    src={s.imageUrl}
                    alt={`Symbole`}
                    isSelected={selectedSymbols.includes(s.id)}
                    onValueChange={(isSelected) => { handleCheckboxClick(isSelected, s.id) }}
                    className="h-[5vw] w-[5vw]"
                  />
                  <Autocomplete
                    aria-label="autocomplete"
                    defaultItems={s.labels}
                    allowsCustomValue={true}
                    defaultInputValue={s.name}
                    onInputChange={(value) => updateLabel(value, s.id)}
                  >
                    {(lab) => <AutocompleteItem key={lab.id} aria-label="autocomplete">{lab.value}</AutocompleteItem>}
                  </Autocomplete>
                </div>
              </div>
    }, [selectedSymbols]);
    

    return (
      <div className="flex flex-row overflow-hidden">
        <div className={`flex flex-col px-4 max-h-full w-full`}>
          <h3 className="text-center text-xl h3">Légende</h3>
          <ScrollShadow hideScrollBar className={`py-2 pr-6 w-full flex flex-row justify-around`}>
            <Image src={legendImage} className="flex border border-gray-300"/>
          </ScrollShadow>
        </div>
        <div className={`flex flex-col px-4 w-full`}>
          <h3 className="text-center text-xl h3 flex-shrink-0">Choisir les symboles</h3>
          <div className="mt-4 mx-auto flex flex-row justify-center gap-x-[10px] min-w-[50%] max-w-[400px] flex-shrink-0">
            <Checkbox color="danger" size="sm" defaultSelected={allSymbolsSelected} isSelected={allSymbolsSelected} onValueChange={(v) => selectUnselect(v)}>
              {allSymbolsSelected ? "Désélectionner tout" : "Sélectionner tout"}
            </Checkbox>
          </div>
          <ScrollShadow hideScrollBar className={`py-2 pr-6 w-full flex flex-row justify-around flex-grow`}>
            <div className={`flex flex-col gap-2`}>
              {symbols.map((s) => (
                getSymbolDiv(s)
              ))}
            </div>
          </ScrollShadow>
          <div className="text-center h4 subtitle flex-shrink-0">{selectedSymbols.length} {selectedSymbols.length <= 1 ? ' symbole sélectionné' : 'symboles sélectionnés'}</div>
        </div>
      </div>
    );
  };

  

export default SymbolSelector;

"use client";

import React, { useState } from "react";
import {
  Button,
  Divider,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Textarea,
} from "@nextui-org/react";
import {Icon} from "@iconify/react";

import FeedbackRating from "./feedback-rating";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import useAzureFuncApp from "../../hooks/azure/useAzureFuncApp";
import useAzureBlobs from "../../hooks/azure/useAzureBlobs";
import { useEntraUser } from "../../EntraUserProvider";

export default function FeedbackModal({isOpen, onOpen, onOpenChange}) {
  const navigate = useNavigate();
  const { launch_request } = useAzureFuncApp();  
  const { uploadFile } = useAzureBlobs();
  const { userData } = useEntraUser();

  const [message, setMessage] = useState("");
  const [ratingValue, setRatingValue] = useState(undefined);
  
  async function sendFeedback() {
    const payload = {
      "firstname": userData?.firstname,
      "lastname": userData?.lastname,
      "email": userData?.email,
      "date": new Date(Date.now()).toLocaleString(),
      "message": message,
      "note": ratingValue
    };

    let [status_code, result] = await launch_request(
      config.apiSaveFeedbacks,
      payload,
      {
        loading_message: "Envoi de l'email en cours",
      }
    );

    if (status_code === 200)
    {
      console.log("Formulaire soumis !");
    }

  }

  return (
      <Modal isOpen={isOpen} shouldBlockScroll={false} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <ModalBody>
              <ModalHeader className="flex-col items-center gap-1 px-0 text-center">
                <h1 className="text-xl">Aidez-nous à nous améliorer !</h1>
                <p className="text-small font-normal text-default-500">
                    Merci pour votre utilisation de notre service !<br />
                    Si vous avez des idées ou des retours pour nous aider à améliorer notre service, n'hésitez pas à nous les partager.
                </p>
              </ModalHeader>
              <form
                className="flex w-full flex-col gap-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  onClose();
                }}
              >
                <Textarea
                  aria-label="Feedback"
                  minRows={8}
                  name="feedback"
                  placeholder="Idées ou suggestions ..."
                  value={message}
                  onValueChange={setMessage}
                  variant="faded"
                />

                <Divider className="my-2" />
                <div className="flex w-full items-center justify-between pb-4">
                  <FeedbackRating name="rating" size="lg" setRatingValue={setRatingValue} />
                  <div className="flex gap-2">
                    <Button color="default" type="button" variant="flat" onPress={onClose}>
                      Annuler
                    </Button>
                    <Button color="primary" type="submit" variant="flat" onPress={sendFeedback}>
                      Valider
                    </Button>
                  </div>
                </div>
              </form>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
  );
}

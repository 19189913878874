import React, { useEffect, useMemo, useState } from "react";
import FileLoader from "../../../../components/file/FileLoader";
import EditableTable from "../../../../components/table/editable-table";
import { useDispatch, useSelector } from "react-redux";

import {Icon} from "@iconify/react";
import { Button, Chip, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tooltip, useDisclosure } from "@nextui-org/react";
import { ActualizeIcon, DeleteIcon } from "../../../../components/Icons";
import { changeStatus, setSasPdf, deleteFile, setCurrentFile, addProjectFile } from "../../../../store/features/Comptage/project-info-slice";
import config from "../../../../config";
import { setFileConfiguration } from "../../../../store/features/Comptage/configuration";
import { clearFiles, setFiles } from "../../../../store/features/PDF/FileSlice";
import { setHits } from "../../../../store/features/Comptage/decompte";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import { setCalibration } from "../../../../store/components/PolygonEditor/ImageSlice";
import { useOutletContext } from "react-router-dom";
import YesNoAlert from "../../../../components/modals/AlertYesNo";

const statusColorMap = {
    ready: {
        name: "Prêt",
        color:"success"
    },
    inprogress: {
        name: "En cours de préparation",
        color:"warning"
    },
    unready: {
        name: "Non préparé",
        color:"danger"
    }
  };


const containerName = "comptagev2";

export default function Files () {
    const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
    const {isOpen, onOpen, onOpenChange: defaultOnOpenChange, onClose} = useDisclosure();
    const { launch_request } = useAzureFuncApp();

    const projectFiles = useSelector((state) => state.Comptage.ProjectInfos.project_files);
    const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
    const projectName = useSelector((state) => state.Comptage.ProjectInfos.project_name);
    const user = useSelector((state) => state.Comptage.ProjectInfos.creator_name);
    const dispatch = useDispatch();
    
    const data = useMemo(() => {
        return projectFiles.map((x, i) => {
            return {
                id: i,
                photo: undefined,
                name: x.name,
                pages: x.pages === undefined ? 0 : x.pages.length,
                status: x.status,
                is_current: x.is_current,
                action: undefined
            };
        });
    }, [projectFiles]);

    const [filters, setFilters] = useState([]);
    const [changingCurrentFile, setChangingCurrentFile] = useState(false);

    useEffect(() => {
        if (!changingCurrentFile) {
            prepareFirstFile();
        }

        async function prepareFirstFile() {
            if (data.every(d => d.status === "unready") && data.length > 0) {
                setChangingCurrentFile(true);
                await actualizeFile(data[0].name);
                setChangingCurrentFile(false);
            }
        }
    }, [data]);

    const onChangeCurrentFile = async (filename) => {
        setChangingCurrentFile()
        setLoadingMessage("Changement du fichier courant...");
        setIsLoading(true);

        // Set current file
        let payload = {
            'function': 'set_current',
            'username': user,
            'project_name': projectName,
            'pdf_name': filename,
            'container_name': containerName
        };
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            dispatch(setCurrentFile(filename));

            dispatch(setFileConfiguration({
                current_file: result.filename,
                pages: result.pages,
                zones: result.zones,
                symbols: result.symbols,
                linears: result.linears
            }));

            dispatch(setCalibration(result.calibration));

            dispatch(setHits({
                hits: result.hits,
                metres: result.metres
            }));
        }

        setIsLoading(false);
    };

    const actualizeFile = async (filename) => {
        dispatch(changeStatus({
            filename: filename,
            status: "inprogress"
        }));

        setLoadingMessage("Préparation du fichier...");
        setIsLoading(true);

        let payload = {
            "function": "pdf2img",
            "container_name": containerName,
            "project_name": projectName,
            "username": user,
            "file": filename,
            "all_pages": true,
            "selected_pages_numbers": []
        };
    
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            let url_pdf = result.url_pdf;
            let previews = result.previews;
            let pages = result.pages;
            dispatch(setSasPdf({
                filename: filename,
                url: url_pdf,
                previews: previews
            }));
            dispatch(changeStatus({
                filename: filename,
                status: "ready",
                pages: pages
            }));

            if (result.set_current) {
                await onChangeCurrentFile(filename);
            }
        } else {
            
            dispatch(changeStatus({
                filename: filename,
                status: "unready"
            }));
        }

        setIsLoading(false);
    };

    const onDeleteFile = async (filename) => {
        setLoadingMessage("Suppression du fichier...");
        setIsLoading(true);

        // Delete file
        let payload = {
            'function': 'delete_file',
            'username': user,
            'project_name': projectName,
            'pdf_name': filename,
            'container_name': containerName
        };
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            dispatch(deleteFile(filename));

            if (filename === currentFile) {
                dispatch(setCurrentFile(undefined));
            }
        }

        setIsLoading(false);
    };
    
    const columns = [
        {
            uid: 'photo',
            name: '',
            isEditable: false,
            type: 'image',
            height: 50,
            sortable: false
        },
        {
            uid: 'name',
            name: 'Nom du fichier',
            isEditable: false,
            type: 'text',
        },
        {
            uid: 'pages',
            name: 'Nombre de pages',
            isEditable: false,
            type: 'number',
            sortable: false
        },
        {
            uid: 'is_current',
            name: '',
            isEditable: false,
            type: 'bool',
            renderCell: (item) => {
                if (item.is_current) {
                    return (<><span className="bg-green-600 rounded-[50%] inline-block w-4 h-4 text-center align-middle leading-4">&#x2713;</span> Sélectionné</>);
                } else {
                    return (<Button isDisabled={item.status !== "ready"} onClick={() => { onChangeCurrentFile(item.name); }}>Sélectionner</Button>);
                }
            }
        },
        {
            uid: 'status',
            name: 'Etat',
            isEditable: false,
            type: 'text',
            renderCell: (item) => {
                return ( <Chip color={statusColorMap[item.status].color}>{statusColorMap[item.status].name}</Chip>);
            }
        },
        {
            uid: 'action',
            name: '',
            isEditable: false,
            type: 'text',
            sortable: false,
            renderCell: (item) => {
                return (<div className="flex flex-row justify-center gap-3">
                    {(item.status === "unready") && <Tooltip color="danger" content="Actualiser le fichier">
                        <span
                        className="text-lg text-danger cursor-pointer active:opacity-50"
                        onClick={() => { actualizeFile(item.name); }}
                        >
                        <ActualizeIcon />
                        </span>
                    </Tooltip>}
                    <Tooltip color="danger" content="Supprimer le fichier">
                        <span
                        className="text-lg text-danger cursor-pointer active:opacity-50"
                        onClick={() => {
                            YesNoAlert({
                                title: "Supprimer",
                                body: "Voulez-vous vrament supprimer le fichier ?",
                                onYes: () => {
                                    onDeleteFile(item.name);
                                }
                            })
                        }}
                        >
                            <DeleteIcon />
                        </span>
                    </Tooltip>
                </div>)
            }
        }
    ];

    const addFile = async (filename, arrayBuffer, totalPages, index) => {
        dispatch(addProjectFile({
            name: filename,
            pages: [],
            is_current: false,
            status: "inprogress"
        }));

        setLoadingMessage("Préparation du fichier...");
        setIsLoading(true);
        onClose();

        dispatch(setFiles([]));

        let payload = {
            "function": "pdf2img",
            "container_name": containerName,
            "project_name": projectName,
            "username": user,
            "file": filename,
            "all_pages": true,
            "selected_pages_numbers": []
        };
    
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            let url_pdf = result.url_pdf;
            let previews = result.previews;
            let pages = result.pages;
            dispatch(setSasPdf({
                filename: filename,
                url: url_pdf,
                previews: previews
            }));
            dispatch(changeStatus({
                filename: filename,
                status: "ready",
                pages: pages
            }));

            if (result.set_current) {
                await onChangeCurrentFile(filename);
            }
        }

        setIsLoading(false);
    };

    const onOpenChange = (isOpen) => {
        dispatch(clearFiles());
        defaultOnOpenChange(isOpen);
    };

    return (
        <div className="flex flex-col justify-start items-end w-full gap-5 px-5 py-5">
            <Button className="min-w-[10%] flex flex-row justify-between" onPress={onOpen}><Icon icon="ic:baseline-plus" /><span>Ajouter</span><span></span></Button>
            <EditableTable
                columns={columns}
                data={data}
                filters={filters}
                showTopRibbon={false}
                // removeWrapper
            />
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} className="max-w-fit">
                <ModalContent>
                {(o) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1"></ModalHeader>
                    <ModalBody>
                        <FileLoader
                            title="Ajoutez un fichier"
                            multiple={false}
                            containerName={containerName}
                            fileType="pdf"
                            className="min-w-[450px]"
                            blobNamePrefix={`${user}/${projectName}/`}
                            onFileLoaded={addFile}
                            backgroundColor="#EFEFEF"
                        />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                    </>
                )}
                </ModalContent>
            </Modal>
        </div>
    );
};

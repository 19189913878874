import React, { useEffect } from "react";


import "./index.css";
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import PdfRoutes from "./routes/PdfRoutes";
import OmiaRoutes from "./routes/OmiaRoutes";
import FileAnalysisRoutes from "./routes/FileAnalysisRoutes";
import ComptageRoutes from "./routes/ComptageRoutes";
import ContractsRoutes from "./routes/ContractsRoutes";
import DevisRoutes from "./routes/DevisRoutes";
import ErrorRoutes from "./routes/ErrorRoutes";
import PymRoutes from "./routes/PymRoutes";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { EntraUserProvider } from './EntraUserProvider';
import LoginPage from "./pages/login";
import { NextUIProvider } from "@nextui-org/react";
import { DarkModeProvider, useDarkMode } from '@rbnd/react-dark-mode';

/**
* If a user is authenticated the Website component is rendered. Otherwise display login page
*/
const MainContent = ({router}) => {
  const { mode, setMode } = useDarkMode();
  let enableDarkmode = false;
  
  return (
      <div>
          <AuthenticatedTemplate>
            <EntraUserProvider>
                <div className="bg-hero-section-centered-navbar overflow-hidden w-screen h-screen">
                  <main className={`${mode === "dark" && enableDarkmode ? "dark text-foreground bg-background" : "bg-transparent"} w-full h-full`}>
                    <div className="overflow-y-auto h-full w-full">
                      <RouterProvider router={router} />
                    </div>
                    </main>
                  </div>
            </EntraUserProvider>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
              <LoginPage />
          </UnauthenticatedTemplate>
      </div>
  );
};

export default function App() {

  useEffect(() => {
    // Check environment variable
    let env = process.env.REACT_APP_ENVIRONMENT;
    const isProduction = env === "production";

    if (!isProduction) {
      // Dynamically create and append the meta noindex tag in non-production environments
      const metaRobots = document.createElement('meta');
      metaRobots.name = 'robots';
      metaRobots.content = 'noindex';
      document.getElementsByTagName('head')[0].appendChild(metaRobots);

      
      const metaGoogle = document.createElement('meta');
      metaGoogle.name = 'googlebot';
      metaGoogle.content = 'noindex';
      document.getElementsByTagName('head')[0].appendChild(metaGoogle);
    }
  });

  //State
  const router = createBrowserRouter([
    MainRoutes,
    PdfRoutes,
    FileAnalysisRoutes,
    ComptageRoutes,
    DevisRoutes,
    OmiaRoutes,
    PymRoutes,
    ContractsRoutes,
    ErrorRoutes,
  ]);
  
  
  //Render
  return (
    <>
      <MainContent router={router} />
    </>
  );
}

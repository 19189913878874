import FiltersWrapper from '../../../../components/table/filters/filters-wrapper'
import {FilterTypeEnum} from "../../../../components/table/filters/filters-types";
import { useSelector } from "react-redux";
import {Icon} from "@iconify/react";

import React, { useState,useMemo,useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import EditableTable from '../../../../components/table/editable-table';
import { updateRoadsProperties } from "../../../../store/features/PYM/GeoSlice";
import LeafletMap from "../map";
import { Spacer,Button } from '@nextui-org/react';
import { useOutletContext } from "react-router-dom";
import config from "../../../../config";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";

const surfaceType = [
  {
    "title": "Pavée",
    "value": "paved"
  },
  {
    "title": "Asphalte",
    "value": "asphalt"
  },
  {
    "title": "Béton",
    "value": "concrete"
  },
  {
    "title": "Gravier",
    "value": "gravel"
  },
  {
    "title": "Herbe",
    "value": "grass"
  },
  {
    "title": "Terre",
    "value": "dirt"
  },
  {
    "title": "Boue",
    "value": "mud"
  },
  {
    "title": "Sable",
    "value": "sand"
  }
]
const categoryType = [
  {
    title: "Axe principal",
    value: "main_axe",
    icon: "solar:video-frame-cut-line-duotone",
  },
  {
    title: "Axe secondaire",
    value: "secondary_axe",
    icon: "solar:branching-paths-down-line-duotone",
  },
  {
    title: "Axe tertiaire",
    value: "tertiaire_axe",
    icon: "solar:home-line-duotone",
  },
  {
    title: "Axe piéton",
    value: "walking_axe",
    icon: "solar:walking-line-duotone",
  },

]
const filterDataItems = [

    {
      type: FilterTypeEnum.TagGroup,
      key: "highway",
      title: "Type",
      defaultOpen: true,
      options: [
        {
          title: "Autoroute",
          value: "motorway",
          icon: "mdi:highway",
        },
        {
          title: "Voie rapide ou voie express",
          value: "trunk",
          icon: "fluent:road-24-filled",
        },
        {
          title: "Nationales",
          value: "primary",
          icon: "fluent:road-24-regular",
        },
        {
          title: "Départementales",
          value: "secondary",
          icon: "material-symbols-light:road",
        },
        {
          title: "Petites routes",
          value: "tertiary",
          icon: "healthicons:paved-road-alt-outline",
        },            {
          title: "Voie de desserte locale",
          value: "unclassified",
          icon: "healthicons:paved-road-outline",
        },            {
          title: "Zone résidentielle",
          value: "residential",
          icon: "fluent:building-townhouse-32-regular",
        },
      ],
    },
    {
      type: FilterTypeEnum.TagGroup,
      key: "category",
      title: "Catégorie",
      defaultOpen: true,
      options: categoryType,
    },
    {
      type: FilterTypeEnum.CheckboxGroup,
      key: "surface",
      title: "Surface",
      defaultOpen: false,
      options: surfaceType,
    },
  ];
const highwayType = [
  {
    title: "Autoroute",
    value: "motorway",
  },
  {
    title: "Voie rapide ou voie express",
    value: "trunk",
  },
  {
    title: "Nationales",
    value: "primary",
  },
  {
    title: "Départementales",
    value: "secondary",
  },
  {
    title: "Petites routes",
    value: "tertiary",
  },            {
    title: "Voie de desserte locale",
    value: "unclassified",
  },            {
    title: "Zone résidentielle",
    value: "residential",
  }]

export default function Voiries({needFilterSideBar=true,needAllRoads=true}) {
  // Déclaration des states 
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  const { launch_request } = useAzureFuncApp();
  const projectInfos = useSelector((state) => state.PYM.ProjectInfos);

  const data = useSelector((state) => state.PYM.GeoData.roads_data);
  const selected_roads = useSelector((state) => state.PYM.GeoData.selected_roads);
  let roads = data.features.map(element => element.properties);

  if(!needAllRoads){
    let idList = selected_roads.map(dict => dict.id);
    roads =  roads.filter(item => idList.includes(item.id));
  }
  
  const [filters, setFilters] = useState([]);
  const [roadsDataTable, setRoadsDataTable] = useState(roads);
  const [subCategoryType, setSubCategoryType] = React.useState([]);
  
  useEffect(() => {
    getRoadsProfile().then((result) => setSubCategoryType(result));
  }, [])

  var  initial_visible_columns = useMemo(() => { 
    return [
      {
        name: "Type",
        uid: "highway",
        valueAccessor: (item) => highwayType.find(x => x.value === item.highway)?.title,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem = { ...newItem, highway: highwayType.find(x => x.title ===value)?.value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
      },
      {
        name: "Catégorie",
        uid: "category",
        valueAccessor: (item) => categoryType.find(x => x.value === item.category)?.title,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem = { ...newItem, category: categoryType.find(x => x.title === value[0])?.value };
          return newItem;
        },
        isEditable: true,
        type: 'list',
        listItems: categoryType.map(item => item.title),
        width: "500px",
      },
      {
        name: "Sous catégorie",
        uid: "subcategory",
        valueAccessor: (item) => subCategoryType.find(x => x.name === item.subcategory)?.name,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem = { ...newItem, subcategory: subCategoryType.find(x => x.name === value[0])?.name };
          return newItem;
        },
        isEditable: true,
        type: 'list',
        listItems: subCategoryType.map(item => item.name),
        width: "500px",
      },
      {
        name: "Surface",
        uid: "surface",
        valueAccessor: (item) => item.surface,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem = { ...newItem, surface: value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
      },
    ].filter(x => x.type !== "list" || (x.type === "list" && x.listItems.length > 0));
  }, [subCategoryType]);


  if ( data === undefined || data.length === 0) {
    return <Navigate to="/pym" />;
  }
  
  async function getRoadsProfile() {
    const payload = {
      functionName: "getRoadsProfiles",
      projectPath: projectInfos.project_path || "",
    };

    setLoadingMessage("Récupération des profils de route...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    if (status_code === 200) {
      setIsLoading(false);
      return result.roadsList
    }
    setIsLoading(false);
  }

  const handleSelectedRoads = (idxSelected) =>{
    setFilters(() => {
      let newFilters = [];

      if (idxSelected.length > 0) {
        newFilters.push({
          key: "",
          values: idxSelected,
          type: FilterTypeEnum.IDInList
        });
      }

      return newFilters;
    });
  }
  async function handleSaveRoadType() {
    const payload = {
      functionName: "saveRoads",
      projectPath: projectInfos.project_path || "",
      roads:roadsDataTable,
    };

    setLoadingMessage("Sauvegarde de la voirie...");
    setIsLoading(true);
    console.log('payload',payload)
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    if (status_code === 200) {
      setIsLoading(false);
      return result.roadsList
    }
    setIsLoading(false);

  }

  const headerButtons = <>
        <Button isIconOnly onClick={() => { handleSaveRoadType() }} className='bg-[#80ed99]' ><Icon icon="fluent:save-24-regular" width={55} height={55} style={{ 'color': 'white' }} /></Button>
  </>

  // Render
  return (
  <>
    {needFilterSideBar &&
    <div className='flex flex-col'>
      <FiltersWrapper
        items={filterDataItems}
        setFilters={setFilters}
        showTitle={false}
        title="Voiries"
        className="overflow-y-auto"
      /> 
      <div className='max-h-[500px] min-h-[300px] w-full pl-[1.5rem] pb-[1.5rem]'>
        <LeafletMap 
          show_roads={true} 
          show_lights={false} 
          onSelection={handleSelectedRoads}
        />
      </div>
    </div> 
    }
    <EditableTable
      pagination={true}
      columns={initial_visible_columns} 
      data={roadsDataTable}
      setData={setRoadsDataTable}
      reduxTableSaveAction={updateRoadsProperties}
      filters={filters}
      tableBackground='bg-white p-4'
      headerButtons={headerButtons}
      />
  </>)
}
import React, { forwardRef, useImperativeHandle } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from '@nextui-org/react';

// HelpModal component using forwardRef
const HelpModal = forwardRef(({
    isOpen, onOpen, onClose,
    videoUrl,
    ...props }, ref) => {

  // Expose open and close methods to the parent via ref
  useImperativeHandle(ref, () => ({
    openModal: onOpen,
    closeModal: onClose,
  }));

  return (
    <>
      {/* Modal Component */}
      <Modal isOpen={isOpen} isDismissable={true} isKeyboardDismissDisabled={false} onClose={onClose} aria-labelledby="modal-title" className='w-fit max-w-fit'>
        <ModalContent>
          <ModalHeader>
            <h1 className="text-xl">
                Aide
            </h1>
          </ModalHeader>
          <ModalBody>
            
            {/* YouTube Video Embed */}
            {videoUrl ? <>
                <p className="text-small font-normal text-default-500">
                    Voici le tutoriel vidéo du service
                </p>
                <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                    <iframe
                        width="560" height="315"
                        src={videoUrl}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                    </iframe>
                </div></> : <div style={{ padding: '20px', backgroundColor: '#f0f0f0', textAlign: 'center' }}>
                <p className="text-small font-normal text-default-500">Nous travaillons sur le tutoriel vidéo. Restez à l'affût, il arrive très bientôt !</p>
              </div>}
          </ModalBody>
          <ModalFooter>
            <Button auto flat color="error" onPress={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default HelpModal;

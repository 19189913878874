import React, { useEffect, useState } from "react";
import PageSelector from "../../../../components/file/page-selector";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSelectedPages } from "../../../../store/features/Comptage/project-info-slice";
import { useOutletContext } from "react-router-dom";
import { setCurrentFileSelectedPages } from "../../../../store/features/Comptage/configuration";

export default function Pages () {
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  const [selectedPages, setSelectedPages] = useState([]);

  const [previewFiles, setPreviewFiles] = useState([]);
  const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
  const projectFiles = useSelector((state) => state.Comptage.ProjectInfos.project_files);
  const dispatch = useDispatch();

  useEffect(() => {
    let pFile = projectFiles.find((pf) => pf.name === currentFile);
    if (pFile !== undefined) {
      retrieve_pdf(pFile);
      setSelectedPages(pFile.pages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]));
    }
  }, []);

  useEffect(() => {
    dispatch(setCurrentSelectedPages({
      filename: currentFile,
      selectedPages: selectedPages
    }));
    dispatch(setCurrentFileSelectedPages(selectedPages));
  }, [selectedPages]);

  return (
      <PageSelector
        pdfName={currentFile}
        pagePreviews={previewFiles}
        selectedPages={selectedPages}
        setSelectedPages={setSelectedPages} />
  );

  async function retrieve_pdf(pFile) {
    setIsLoading(true);
    const filePreviews = pFile.previews;
    setPreviewFiles(filePreviews);
    setIsLoading(false);
  }
};
